import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { supabase } from '../../config/supabaseConfig';
import { TASK_STATUS, TIMELINE_STEPS } from '../../config/constants';
import { createTasksForContact, updateTask } from '../../utils/taskUtils';
import TaskHeader from './TaskHeader';
import TaskTimeline from './TaskTimeline';
import TaskList from './TaskList';
import TaskModal from './TaskModal';

const TaskManager = () => {
  const { contactId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [isCustomTask, setIsCustomTask] = useState(false);

  
  useEffect(() => {
    if (contactId) {
      initialize();
    }
  }, [contactId]);
// In TaskManager.jsx

const initialize = async () => {
  try {
    setLoading(true);
    setError(null);

    // Fetch full contact details with team member data
    const { data: contactData, error: contactError } = await supabase
      .from('contacts')
      .select(`
        *,
        team_members!contacts_assigned_to_fkey (
          id,
          first_name,
          last_name,
          email
        )
      `)
      .eq('id', contactId)
      .single();

    if (contactError) throw contactError;
    setContact(contactData);

    // Just fetch tasks - no need to create them as they were created during import
    await fetchTasks();
  } catch (err) {
    console.error('Error initializing:', err);
    setError(err.message);
  } finally {
    setLoading(false);
  }
};

const fetchTasks = async () => {
  try {
    setError(null);
    console.log('Fetching tasks for contact:', contactId);
    
    const { data, error } = await supabase
      .from('tasks')
      .select(`
        *,
        contacts!tasks_contact_id_fkey (
          *,
          team_members!contacts_assigned_to_fkey (
            id,
            first_name,
            last_name,
            email
          )
        )
      `)
      .eq('contact_id', contactId)
      .order('day_number', { ascending: true });

    if (error) throw error;
    console.log('Raw tasks data:', data);

    const tasksByDay = data.reduce((acc, task) => {
      const day = task.day_number;
      if (!acc[day]) acc[day] = [];
      acc[day].push(task);
      return acc;
    }, {});
    console.log('Tasks grouped by day:', tasksByDay);

    const transformedTasks = data?.map(task => ({
      ...task,
      contact: task.contacts
    })) || [];

    console.log('Transformed tasks:', transformedTasks);
    setTasks(transformedTasks);
  } catch (err) {
    console.error('Error fetching tasks:', err);
    setError(err.message);
  }
};

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      setError(null);
      const task = tasks.find(t => t.id === taskId);
      if (!task) throw new Error('Task not found');

      // Optimistically update the UI
      setTasks(currentTasks => 
        currentTasks.map(task => 
          task.id === taskId 
            ? {
                ...task,
                status: newStatus,
                completed_date: newStatus === TASK_STATUS.COMPLETED 
                  ? new Date().toISOString() 
                  : null
              }
            : task
        )
      );

      await updateTask(taskId, {
        ...task,
        status: newStatus,
        completed_date: newStatus === TASK_STATUS.COMPLETED ? new Date().toISOString() : null
      });
    } catch (err) {
      console.error('Error updating task status:', err);
      setError(err.message);
      await fetchTasks(); // Revert on error
    }
  };

  const handleTaskClick = (task) => {
    setEditingTask({
      ...task,
      contact_id: task.contact_id || contactId,
      scheduled_date: task.scheduled_date 
        ? new Date(task.scheduled_date).toISOString().split('T')[0] 
        : new Date().toISOString().split('T')[0],
      status: task.status || TASK_STATUS.PENDING,
      day_number: task.day_number || 1,
      template_name: task.template_name || ''
    });
    setIsCustomTask(!TIMELINE_STEPS.find(step => step.day === task.day_number));
    setIsModalOpen(true);
  };

  const handleCreateTask = (dayNumber) => {
    const today = new Date().toISOString().split('T')[0];
    const timelineStep = TIMELINE_STEPS.find(step => step.day === dayNumber);
    const isCustom = !dayNumber;
    
    const newTask = {
      contact_id: contactId,
      status: TASK_STATUS.PENDING,
      task_type: timelineStep ? timelineStep.type : 'EMAIL',
      day_number: dayNumber || 1,
      scheduled_date: today,
      name: timelineStep ? timelineStep.name : '',
      notes: '',
      template_name: ''
    };
    
    setEditingTask(newTask);
    setIsCustomTask(isCustom);
    setIsModalOpen(true);
  };

  const handleSaveTask = async () => {
    try {
      setError(null);
  
      // Validate required fields
      const requiredFields = ['contact_id', 'task_type', 'name', 'day_number', 'scheduled_date'];
      const missingFields = requiredFields.filter(field => {
        const value = editingTask[field];
        return value === undefined || value === null || value === '';
      });
  
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }
  
      const taskData = {
        contact_id: contactId,
        task_type: editingTask.task_type,
        day_number: parseInt(editingTask.day_number),
        scheduled_date: new Date(editingTask.scheduled_date).toISOString(),
        status: editingTask.status || TASK_STATUS.PENDING,
        name: editingTask.name.trim(),
        notes: editingTask.notes || '',
        template_name: editingTask.template_name ? editingTask.template_name.trim() : null
      };
  
      let result;
      if (editingTask.id) {
        const updatedTask = await updateTask(editingTask.id, taskData);
        result = { data: [updatedTask] };
      } else {
        result = await supabase
          .from('tasks')
          .insert([taskData])
          .select(`
            *,
            contacts!tasks_contact_id_fkey (
              *,
              team_members!contacts_assigned_to_fkey (
                id,
                first_name,
                last_name,
                email
              )
            )
          `);
      }
  
      const { data, error } = result;
      if (error) throw error;
  
      if (data && data[0]) {
        const transformedTask = {
          ...data[0],
          contact: data[0].contacts
        };
        
        setTasks(currentTasks => {
          if (editingTask.id) {
            return currentTasks.map(task => 
              task.id === editingTask.id ? transformedTask : task
            );
          } else {
            return [...currentTasks, transformedTask];
          }
        });
        handleCloseModal();
      } else {
        throw new Error('No data returned from the server');
      }
    } catch (err) {
      console.error('Error saving task:', err);
      setError(err.message);
    }
  };

  const handleDeleteTask = async () => {
    if (!editingTask?.id) return;

    try {
      setError(null);
      
      // Optimistically update UI
      setTasks(currentTasks => 
        currentTasks.filter(task => task.id !== editingTask.id)
      );

      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', editingTask.id);

      if (error) throw error;

      handleCloseModal();
    } catch (err) {
      console.error('Error deleting task:', err);
      setError(err.message);
      await fetchTasks(); // Revert on error
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingTask(null);
    setIsCustomTask(false);
    setError(null);
  };

  const filteredTasks = tasks.filter(task => 
    statusFilter === 'ALL' || task.status === statusFilter
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 text-red-800 rounded-lg">
        <h3 className="font-semibold">Error</h3>
        <p>{error}</p>
      </div>
    );
  }


  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <TaskHeader 
          statusFilter={statusFilter}
          onStatusFilterChange={setStatusFilter}
          contactName={contact ? `${contact.first_name} ${contact.last_name}` : null}
        />
        <button 
          onClick={() => handleCreateTask()}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Task
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border dark:border-gray-700">
        <TaskTimeline 
          tasks={tasks}
          contact={contact}
          onTaskClick={handleTaskClick}
          onStatusChange={handleStatusChange}
        />

        <TaskList 
          tasks={filteredTasks}
          contact={contact}
          onStatusChange={handleStatusChange}
          onTaskClick={handleTaskClick}
          statusFilter={statusFilter}
        />
      </div>

      <TaskModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        editingTask={editingTask}
        setEditingTask={setEditingTask}
        isCustomTask={isCustomTask}
        onSave={handleSaveTask}
        onDelete={handleDeleteTask}
        error={error}
        contact={contact}
      />
    </div>
  );
};

export default TaskManager;