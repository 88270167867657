import React, { useState, useEffect } from 'react';
import { Plus, Pencil, Save, X, AlertCircle, GripVertical } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { supabase } from '../../config/supabaseConfig';
import { TASK_TYPES } from '../../config/constants';

const TaskDefinitionManager = () => {
  const [tasks, setTasks] = useState([]);
  const [editingTask, setEditingTask] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase
        .from('marketing_plan_tasks')
        .select('*')
        .order('sort_order');
      
      if (error) throw error;
      setTasks(data || []);
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setError('Failed to load marketing plan tasks');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      if (!editingTask.name || !editingTask.task_type || !editingTask.day_number) {
        throw new Error('Name, type, and day number are required');
      }

      const taskData = {
        ...editingTask,
        day_number: parseInt(editingTask.day_number),
        sort_order: editingTask.sort_order || tasks.length + 1
      };

      let result;
      if (editingTask.id) {
        result = await supabase
          .from('marketing_plan_tasks')
          .update(taskData)
          .eq('id', editingTask.id)
          .select();
      } else {
        result = await supabase
          .from('marketing_plan_tasks')
          .insert([taskData])
          .select();
      }

      if (result.error) throw result.error;
      
      await fetchTasks();
      setEditingTask(null);
    } catch (err) {
      console.error('Error saving task:', err);
      setError(err.message);
    }
  };

  const handleDelete = async (taskId) => {
    if (!window.confirm('Are you sure you want to delete this task?')) return;

    try {
      const { error } = await supabase
        .from('marketing_plan_tasks')
        .delete()
        .eq('id', taskId);

      if (error) throw error;
      await fetchTasks();
    } catch (err) {
      console.error('Error deleting task:', err);
      setError('Failed to delete task');
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update sort order
    const updatedItems = items.map((item, index) => ({
      ...item,
      sort_order: index + 1
    }));

    setTasks(updatedItems);

    try {
      const { error } = await supabase
        .from('marketing_plan_tasks')
        .upsert(updatedItems.map(({ id, sort_order }) => ({
          id,
          sort_order
        })));

      if (error) throw error;
    } catch (err) {
      console.error('Error updating task order:', err);
      setError('Failed to update task order');
      await fetchTasks(); // Revert on error
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Marketing Plan Tasks
        </h2>
        <button
          onClick={() => setEditingTask({ is_active: true })}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Task
        </button>
      </div>

      {/* Error Display */}
      {error && (
        <div className="p-4 bg-red-50 text-red-800 dark:bg-red-900/50 dark:text-red-200 rounded-lg flex items-start gap-2">
          <AlertCircle className="h-5 w-5 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      {/* Task List */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tasks">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-2"
            >
              {tasks.map((task, index) => (
                <Draggable 
                  key={task.id} 
                  draggableId={task.id} 
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={`
                        flex items-center gap-4 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm
                        ${!task.is_active ? 'opacity-50' : ''}
                      `}
                    >
                      {/* Drag Handle */}
                      <div {...provided.dragHandleProps} className="cursor-grab">
                        <GripVertical className="h-5 w-5 text-gray-400" />
                      </div>
                      
                      {/* Day Number */}
                      <div className="w-20">
                        <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          Day
                        </div>
                        <div className="text-lg font-bold text-gray-900 dark:text-white">
                          {task.day_number}
                        </div>
                      </div>

                      {/* Task Info */}
                      <div className="flex-grow">
                        <h3 className="font-medium text-gray-900 dark:text-white">
                          {task.name}
                        </h3>
                        <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="px-2 py-0.5 bg-gray-100 dark:bg-gray-700 rounded">
                            {TASK_TYPES[task.task_type]?.name}
                          </span>
                          {task.description && (
                            <span className="truncate">
                              {task.description}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Actions */}
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => setEditingTask(task)}
                          className="p-2 text-gray-400 hover:text-gray-500"
                        >
                          <Pencil className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Edit Modal */}
      {editingTask && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-lg">
            {/* Modal Header */}
            <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {editingTask.id ? 'Edit Task' : 'New Task'}
              </h3>
              <button
                onClick={() => setEditingTask(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  Task Name*
                </label>
                <input
                  type="text"
                  value={editingTask.name || ''}
                  onChange={(e) => setEditingTask({
                    ...editingTask,
                    name: e.target.value
                  })}
                  className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                  placeholder="Enter task name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  Task Type*
                </label>
                <select
                  value={editingTask.task_type || ''}
                  onChange={(e) => setEditingTask({
                    ...editingTask,
                    task_type: e.target.value
                  })}
                  className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                >
                  <option value="">Select type</option>
                  {Object.entries(TASK_TYPES).map(([type, info]) => (
                    <option key={type} value={type}>{info.name}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  Day Number*
                </label>
                <input
                  type="number"
                  min="1"
                  value={editingTask.day_number || ''}
                  onChange={(e) => setEditingTask({
                    ...editingTask,
                    day_number: e.target.value
                  })}
                  className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  Description
                </label>
                <textarea
                  value={editingTask.description || ''}
                  onChange={(e) => setEditingTask({
                    ...editingTask,
                    description: e.target.value
                  })}
                  rows={3}
                  className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                  placeholder="Enter task description"
                />
              </div>

              <div>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={editingTask.is_active}
                    onChange={(e) => setEditingTask({
                      ...editingTask,
                      is_active: e.target.checked
                    })}
                    className="rounded border-gray-300 dark:border-gray-600 text-blue-600"
                  />
                  <span className="text-sm font-medium">Active</span>
                </label>
              </div>
            </div>

            {/* Modal Footer */}
            <div className="flex justify-between p-4 border-t dark:border-gray-700">
              {editingTask.id && (
                <button
                  onClick={() => handleDelete(editingTask.id)}
                  className="px-4 py-2 text-red-600 hover:text-red-700 font-medium"
                  type="button"
                >
                  Delete Task
                </button>
              )}
              <div className="flex space-x-2 ml-auto">
                <button
                  onClick={() => setEditingTask(null)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  type="button"
                >
                  <Save className="h-5 w-5 mr-2 inline-block" />
                  Save Task
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskDefinitionManager;