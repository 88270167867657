import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import DashboardLayout from './components/Layout/DashboardLayout';
import ContactList from './components/Contacts/ContactList';
import CSVUpload from './components/Import/CSVUpload';
import TaskOverview from './components/Tasks/TaskOverview';
import TaskManager from './components/Tasks/TaskManager';
import TemplateManager from './components/Templates/TemplateManager';
import MarketingLayout from './components/Task-Template/MarketingLayout';
import TaskDefinitionManager from './components/Task-Template/TaskDefinitionManager';
import TemplateTaskManager from './components/Task-Template/TemplateTaskManager';
import Dashboard from './components/Dashboard/Dashboard';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import LoginPage from './components/Auth/LoginPage';
import AcceptInvite from './components/Auth/AcceptInvite';

function App() {
  return (
    <AuthProvider>
      <Router basename="/marketing-tracker">
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth/v1/verify" element={<AcceptInvite />} />
          <Route path="/accept-invite" element={<AcceptInvite />} />

          {/* Protected Routes */}
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" replace />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/contacts" element={<ContactList />} />
                    <Route path="/contacts/:contactId/tasks" element={<TaskManager />} />
                    <Route path="/import" element={<CSVUpload />} />
                    <Route path="/tasks" element={<TaskOverview />} />
                    <Route path="/templates" element={<TemplateManager />} />
                    <Route path="/settings" element={<div>Settings Coming Soon</div>} />
                    <Route path="/marketing" element={<MarketingLayout />}>
                      <Route index element={<Navigate to="/marketing/tasks" replace />} />
                      <Route path="tasks" element={<TaskDefinitionManager />} />
                      <Route path="templates" element={<TemplateTaskManager />} />
                    </Route>
                  </Routes>
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;