import React, { useState, useEffect } from 'react';
import {
 BarChart,
 Bar,
 LineChart,
 Line,
 XAxis,
 YAxis,
 CartesianGrid,
 Tooltip,
 Legend,
 ResponsiveContainer,
 PieChart,
 Pie,
 Cell
} from 'recharts';
import {
 Users,
 CheckCircle,
 Clock,
 AlertCircle,
 Inbox,
 UserPlus,
 Mail,
 Phone,
 Linkedin
} from 'lucide-react';
import { supabase } from '../../config/supabaseConfig';
import { CONTACT_STATUS, TASK_TYPES } from '../../config/constants';

const Dashboard = () => {
 const [stats, setStats] = useState({
   totalContacts: 0,
   newContacts: 0,
   activeContacts: 0,
   completedContacts: 0,
   taskCompletion: 0,
   responseRate: 0
 });
 const [tasksByType, setTasksByType] = useState([]);
 const [tasksByStatus, setTasksByStatus] = useState([]);
 const [contactsByDay, setContactsByDay] = useState([]);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
   fetchDashboardData();
 }, []);

 const fetchDashboardData = async () => {
   try {
     setLoading(true);

     // Fetch contacts with their tasks
     const { data: contacts, error: contactsError } = await supabase
       .from('contacts')
       .select(`
         *,
         tasks (
           *
         )
       `);

     if (contactsError) throw contactsError;

     // Calculate statistics
     const totalContacts = contacts.length;
     const newContacts = contacts.filter(c => c.status === 'NEW').length;
     const activeContacts = contacts.filter(c => 
       ['IN_PROGRESS', 'RESPONDED', 'MEETING_SCHEDULED'].includes(c.status)
     ).length;
     const completedContacts = contacts.filter(c => 
       ['CLOSED_WON', 'CLOSED_LOST'].includes(c.status)
     ).length;

     // Calculate task statistics
     let totalTasks = 0;
     let completedTasks = 0;
     let respondedTasks = 0;
     const taskTypes = {};
     const taskStatuses = {};
     const last30Days = {};

     // Initialize last 30 days
     for (let i = 0; i < 30; i++) {
       const date = new Date();
       date.setDate(date.getDate() - i);
       const dateStr = date.toISOString().split('T')[0];
       last30Days[dateStr] = 0;
     }

     contacts.forEach(contact => {
       // Count contacts by creation date
       const createDate = new Date(contact.created_at).toISOString().split('T')[0];
       if (last30Days.hasOwnProperty(createDate)) {
         last30Days[createDate]++;
       }

       // Process tasks
       contact.tasks?.forEach(task => {
         totalTasks++;
         
         // Count by type
         taskTypes[task.task_type] = (taskTypes[task.task_type] || 0) + 1;
         
         // Count by status
         taskStatuses[task.status] = (taskStatuses[task.status] || 0) + 1;
         
         if (task.status === 'COMPLETED') completedTasks++;
         if (task.status === 'RESPONDED') respondedTasks++;
       });
     });

     // Format data for charts
     const taskTypeData = Object.entries(taskTypes).map(([type, count]) => ({
       type: TASK_TYPES[type]?.name || type,
       count
     }));

     const taskStatusData = Object.entries(taskStatuses).map(([status, count]) => ({
       status,
       count
     }));

     const contactTrendData = Object.entries(last30Days)
       .sort((a, b) => a[0].localeCompare(b[0]))
       .map(([date, count]) => ({
         date: new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' }),
         count
       }));

     setStats({
       totalContacts,
       newContacts,
       activeContacts,
       completedContacts,
       taskCompletion: totalTasks ? Math.round((completedTasks / totalTasks) * 100) : 0,
       responseRate: totalTasks ? Math.round((respondedTasks / totalTasks) * 100) : 0
     });

     setTasksByType(taskTypeData);
     setTasksByStatus(taskStatusData);
     setContactsByDay(contactTrendData);

   } catch (err) {
     console.error('Error fetching dashboard data:', err);
   } finally {
     setLoading(false);
   }
 };

 const CHART_COLORS = ['#3B82F6', '#10B981', '#F59E0B', '#EF4444'];

 if (loading) {
   return (
     <div className="flex items-center justify-center h-64">
       <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
     </div>
   );
 }

 return (
   <div className="space-y-6">
     {/* Header */}
     <div>
       <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
         Dashboard
       </h2>
       <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
         Overview of your outreach campaign performance
       </p>
     </div>

     {/* Stats Cards */}
     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
       <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
         <div className="flex items-center">
           <div className="p-3 bg-blue-100 dark:bg-blue-900/50 rounded-lg">
             <Users className="h-6 w-6 text-blue-600 dark:text-blue-400" />
           </div>
           <div className="ml-4">
             <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
               Total Contacts
             </p>
             <p className="text-2xl font-semibold text-gray-900 dark:text-white">
               {stats.totalContacts}
             </p>
           </div>
         </div>
       </div>

       <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
         <div className="flex items-center">
           <div className="p-3 bg-green-100 dark:bg-green-900/50 rounded-lg">
             <UserPlus className="h-6 w-6 text-green-600 dark:text-green-400" />
           </div>
           <div className="ml-4">
             <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
               Active Contacts
             </p>
             <p className="text-2xl font-semibold text-gray-900 dark:text-white">
               {stats.activeContacts}
             </p>
           </div>
         </div>
       </div>

       <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
         <div className="flex items-center">
           <div className="p-3 bg-yellow-100 dark:bg-yellow-900/50 rounded-lg">
             <CheckCircle className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
           </div>
           <div className="ml-4">
             <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
               Task Completion
             </p>
             <p className="text-2xl font-semibold text-gray-900 dark:text-white">
               {stats.taskCompletion}%
             </p>
           </div>
         </div>
       </div>

       <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
         <div className="flex items-center">
           <div className="p-3 bg-red-100 dark:bg-red-900/50 rounded-lg">
             <Inbox className="h-6 w-6 text-red-600 dark:text-red-400" />
           </div>
           <div className="ml-4">
             <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
               Response Rate
             </p>
             <p className="text-2xl font-semibold text-gray-900 dark:text-white">
               {stats.responseRate}%
             </p>
           </div>
         </div>
       </div>
     </div>

     {/* Charts Row 1 */}
     <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
       {/* Contact Trend */}
       <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
         <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
           Contact Trend
         </h3>
         <div className="h-80">
           <ResponsiveContainer width="100%" height="100%">
             <LineChart data={contactsByDay}>
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis 
                 dataKey="date" 
                 tick={{ fontSize: 12 }}
                 interval="preserveStartEnd"
               />
               <YAxis />
               <Tooltip />
               <Legend />
               <Line 
                 type="monotone" 
                 dataKey="count" 
                 stroke="#3B82F6" 
                 name="New Contacts"
                 strokeWidth={2}
               />
             </LineChart>
           </ResponsiveContainer>
         </div>
       </div>

       {/* Tasks by Type */}
       <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
         <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
           Tasks by Type
         </h3>
         <div className="h-80">
           <ResponsiveContainer width="100%" height="100%">
             <BarChart data={tasksByType}>
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis 
                 dataKey="type" 
                 tick={{ fontSize: 12 }}
               />
               <YAxis />
               <Tooltip />
               <Legend />
               <Bar 
                 dataKey="count" 
                 fill="#3B82F6" 
                 name="Tasks"
               />
             </BarChart>
           </ResponsiveContainer>
         </div>
       </div>
     </div>

     {/* Charts Row 2 */}
     <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
       {/* Task Status Distribution */}
       <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
         <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
           Task Status Distribution
         </h3>
         <div className="h-80">
           <ResponsiveContainer width="100%" height="100%">
             <PieChart>
               <Pie
                 data={tasksByStatus}
                 dataKey="count"
                 nameKey="status"
                 cx="50%"
                 cy="50%"
                 outerRadius={100}
                 label
               >
                 {tasksByStatus.map((_, index) => (
                   <Cell 
                     key={`cell-${index}`} 
                     fill={CHART_COLORS[index % CHART_COLORS.length]} 
                   />
                 ))}
               </Pie>
               <Tooltip />
               <Legend />
             </PieChart>
           </ResponsiveContainer>
         </div>
       </div>

       {/* Contact Status Distribution */}
       <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
         <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
           Contact Status Distribution
         </h3>
         <div className="grid grid-cols-2 gap-4">
           <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
             <div className="flex items-center justify-between mb-2">
               <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                 New
               </span>
               <span className="text-sm font-semibold text-gray-900 dark:text-white">
                 {stats.newContacts}
               </span>
             </div>
             <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
               <div
                 className="bg-blue-600 h-2 rounded-full"
                 style={{ width: `${(stats.newContacts / stats.totalContacts) * 100}%` }}
               />
             </div>
           </div>

           <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
             <div className="flex items-center justify-between mb-2">
               <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                 Active
               </span>
               <span className="text-sm font-semibold text-gray-900 dark:text-white">
                 {stats.activeContacts}
               </span>
             </div>
             <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
               <div
                 className="bg-green-600 h-2 rounded-full"
                 style={{ width: `${(stats.activeContacts / stats.totalContacts) * 100}%` }}
               />
             </div>
           </div>

           <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
             <div className="flex items-center justify-between mb-2">
               <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                 Completed
               </span>
               <span className="text-sm font-semibold text-gray-900 dark:text-white">
                 {stats.completedContacts}
               </span>
             </div>
             <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
               <div
                 className="bg-yellow-600 h-2 rounded-full"
                 style={{ width: `${(stats.completedContacts / stats.totalContacts) * 100}%` }}
               />
             </div>
           </div>

           <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
             <div className="flex items-center justify-between mb-2">
               <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                 Response Rate
               </span>
               <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                 Response Rate
               </span>
               <span className="text-sm font-semibold text-gray-900 dark:text-white">
                 {stats.responseRate}%
               </span>
             </div>
             <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
               <div
                 className="bg-red-600 h-2 rounded-full"
                 style={{ width: `${stats.responseRate}%` }}
               />
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default Dashboard;