import React, { useState, useCallback } from 'react';
import { Upload, AlertCircle, CheckCircle } from 'lucide-react';
import Papa from 'papaparse';
import { supabase } from '../../config/supabaseConfig';
import { createTasksForContact } from '../../utils/taskUtils';
import { CSV_FIELD_MAPPINGS } from '../../config/constants';

const CSVUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState({ type: '', message: '' });
  const [progress, setProgress] = useState(0);
  const [validationErrors, setValidationErrors] = useState([]);

  const validateCSVStructure = (headers) => {
    const requiredFields = ['First Name', 'Last Name', 'Email'];
    const missingFields = requiredFields.filter(field => !headers.includes(field));
    
    if (missingFields.length > 0) {
      throw new Error(`Missing required columns: ${missingFields.join(', ')}`);
    }
  };

  const processContacts = async (parsedData) => {
    try {
      // Validate CSV structure
      validateCSVStructure(parsedData.meta.fields);

      const contacts = parsedData.data.map(row => ({
        first_name: row['First Name'] || '',
        last_name: row['Last Name'] || '',
        title: row['Title'] || '',
        email: row['Email'] || '',
        email_status: row['Email Status'] || '',
        email_confidence: row['Email Confidence'] || '',
        primary_email_catch_all_status: row['Primary Email Catch-all Status'] || '',
        primary_email_last_verified_at: row['Primary Email Last Verified At'] || null,
        seniority: row['Seniority'] || '',
        departments: row['Departments'] ? row['Departments'].split(',').map(d => d.trim()) : [],
        
        company: row['Company'] || '',
        company_name_for_emails: row['Company Name for Emails'] || '',
        industry: row['Industry'] || '',
        keywords: row['Keywords'] ? row['Keywords'].split(',').map(k => k.trim()) : [],
        annual_revenue: row['Annual Revenue'] ? parseFloat(row['Annual Revenue']) : null,
        total_funding: row['Total Funding'] ? parseFloat(row['Total Funding']) : null,
        latest_funding: row['Latest Funding'] || '',
        latest_funding_amount: row['Latest Funding Amount'] ? parseFloat(row['Latest Funding Amount']) : null,
        last_raised_at: row['Last Raised At'] || null,
        num_employees: row['# Employees'] ? parseInt(row['# Employees']) : null,
        
        first_phone: row['First Phone'] || '',
        work_direct_phone: row['Work Direct Phone'] || '',
        home_phone: row['Home Phone'] || '',
        mobile_phone: row['Mobile Phone'] || '',
        corporate_phone: row['Corporate Phone'] || '',
        other_phone: row['Other Phone'] || '',
        
        person_linkedin_url: row['Person Linkedin Url'] || '',
        website: row['Website'] || '',
        company_linkedin_url: row['Company Linkedin Url'] || '',
        facebook_url: row['Facebook Url'] || '',
        twitter_url: row['Twitter Url'] || '',
        
        city: row['City'] || '',
        state: row['State'] || '',
        country: row['Country'] || '',
        company_address: row['Company Address'] || '',
        company_city: row['Company City'] || '',
        company_state: row['Company State'] || '',
        company_country: row['Company Country'] || '',
        company_phone: row['Company Phone'] || '',
        
        seo_description: row['SEO Description'] || '',
        technologies: row['Technologies'] ? row['Technologies'].split(',').map(t => t.trim()) : [],
        number_of_retail_locations: row['Number of Retail Locations'] ? 
          parseInt(row['Number of Retail Locations']) : null,
        
        status: 'NEW',
        email_sent: false,
        email_opened: false,
        email_bounced: false,
        replied: false,
        demoed: false
      }));

      const validationErrors = [];
      contacts.forEach((contact, index) => {
        if (!contact.first_name || !contact.last_name) {
          validationErrors.push(`Row ${index + 2}: Missing name`);
        }
        if (contact.email && !contact.email.includes('@')) {
          validationErrors.push(`Row ${index + 2}: Invalid email format`);
        }
      });

      if (validationErrors.length > 0) {
        setValidationErrors(validationErrors);
        throw new Error('Validation failed. Please check the error messages.');
      }

      // Insert contacts in batches of 50
      const batchSize = 50;
      let totalProcessed = 0;

      for (let i = 0; i < contacts.length; i += batchSize) {
        const batch = contacts.slice(i, i + batchSize);
        
        // Insert batch of contacts and get their IDs
        const { data: insertedContacts, error: insertError } = await supabase
          .from('contacts')
          .insert(batch)
          .select('id');
        
        if (insertError) throw insertError;
        
        // Create tasks for each contact in the batch
        if (insertedContacts) {
          await Promise.all(insertedContacts.map(contact => 
            createTasksForContact(contact.id)
          ));
        }
        
        totalProcessed += batch.length;
        setProgress(Math.round((totalProcessed / contacts.length) * 100));
      }

      return contacts.length;
    } catch (error) {
      console.error('Error processing contacts:', error);
      throw error;
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Debug logging
    console.log('File type:', file.type);
    console.log('File name:', file.name);

    // Check both MIME type and file extension
    const isCSV = file.type === 'text/csv' || 
                 file.type === 'application/vnd.ms-excel' ||
                 file.type === 'application/csv' ||
                 file.name.toLowerCase().endsWith('.csv');

    if (!isCSV) {
      console.log('File rejected - not recognized as CSV');
      setStatus({
        type: 'error',
        message: 'Please upload a CSV file'
      });
      return;
    }

    setUploading(true);
    setStatus({ type: '', message: '' });
    setValidationErrors([]);
    setProgress(0);

    try {
      console.log('Starting CSV parse');
      const results = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: 'greedy',
          transformHeader: header => header.trim(),
          complete: resolve,
          error: (error) => {
            console.error('Papa Parse error:', error);
            reject(error);
          }
        });
      });

      console.log('CSV parsed successfully:', results.meta);
      const contactsCount = await processContacts(results);
      
      setStatus({
        type: 'success',
        message: `Successfully imported ${contactsCount} contacts with their 45-day strategy tasks`
      });
    } catch (error) {
      console.error('Error uploading contacts:', error);
      setStatus({
        type: 'error',
        message: error.message || 'Error uploading contacts. Please try again.'
      });
    } finally {
      setUploading(false);
      setProgress(0);
      // Clear the file input
      event.target.value = '';
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <div className="mb-8">
        <h1 className="text-2xl font-bold mb-2">Import Contacts</h1>
        <p className="text-gray-600 dark:text-gray-300">
          Upload your CSV file to import contacts. The file should include contact information 
          in the Apollo export format.
        </p>
      </div>

      {/* Upload Area */}
      <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-8 text-center">
        <label className="block cursor-pointer">
          <input
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
            disabled={uploading}
            className="hidden"
          />
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-gray-600 dark:text-gray-300">
            {uploading ? 'Uploading...' : 'Click to upload or drag and drop'}
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400">CSV files only</p>
        </label>
      </div>

      {/* Progress Bar */}
      {uploading && progress > 0 && (
        <div className="mt-4">
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full transition-all duration-150"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">
            Uploading: {progress}%
          </p>
        </div>
      )}

      {/* Validation Errors */}
      {validationErrors.length > 0 && (
        <div className="mt-4 p-4 bg-yellow-50 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-200 rounded-lg">
          <h3 className="font-semibold mb-2">Validation Errors</h3>
          <ul className="list-disc list-inside space-y-1">
            {validationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Status Messages */}
      {status.message && (
        <div className={`mt-4 p-4 rounded-lg flex items-start gap-2 ${
          status.type === 'error' 
            ? 'bg-red-50 text-red-800 dark:bg-red-900/50 dark:text-red-200' 
            : 'bg-green-50 text-green-800 dark:bg-green-900/50 dark:text-green-200'
        }`}>
          {status.type === 'error' ? (
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
          ) : (
            <CheckCircle className="h-5 w-5 flex-shrink-0" />
          )}
          <div>
            <h3 className="font-semibold">
              {status.type === 'error' ? 'Error' : 'Success'}
            </h3>
            <p>{status.message}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CSVUpload;