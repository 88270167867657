import React, { useState, useEffect } from 'react';
import { X, AlertCircle } from 'lucide-react';
import { supabase } from '../../config/supabaseConfig';
import { TASK_STATUS, TASK_TYPES, TIMELINE_STEPS } from '../../config/constants';
import TemplatePreview from '../Templates/TemplatePreview';
import TemplateHTMLPreview from '../Templates/TemplateHTMLPreview';

const TaskModal = ({ 
  isOpen, 
  onClose, 
  editingTask, 
  setEditingTask, 
  isCustomTask, 
  onSave,
  onDelete,
  error: parentError
}) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const [contact, setContact] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchTemplates();
      fetchCurrentUser();
      fetchContactData();
    }
  }, [isOpen, editingTask?.task_type, editingTask?.contact_id]);

  const fetchContactData = async () => {
    if (!editingTask?.contact_id) return;
    
    try {
      const { data, error } = await supabase
        .from('contacts')
        .select(`
          id,
          first_name,
          last_name,
          title,
          company,
          email,
          assigned_to,
          team_members!contacts_assigned_to_fkey (
            id,
            first_name,
            last_name,
            email
          )
        `)
        .eq('id', editingTask.contact_id)
        .single();
      
      if (error) throw error;
      
      // Modify the contact data to include the assigned team member info
      const contactWithTeamMember = {
        ...data,
        team_members: data.team_members
      };
      
      setContact(contactWithTeamMember);
    } catch (err) {
      console.error('Error fetching contact:', err);
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('team_members')
          .select('id, first_name, last_name, email')
          .eq('id', user.id)
          .single();
        
        if (error) throw error;
        setCurrentUser(data);
      }
    } catch (err) {
      console.error('Error fetching current user:', err);
    }
  };

  const fetchTemplates = async () => {
    if (!editingTask?.task_type) return;
    
    try {
      let query = supabase
        .from('templates')
        .select('*')
        .order('name');

      // Filter templates based on task type
      switch (editingTask.task_type) {
        case 'EMAIL':
          query = query.eq('type', 'EMAIL');
          break;
        case 'LINKEDIN':
          query = query.in('type', ['LINKEDIN_CONNECTION', 'LINKEDIN_MESSAGE']);
          break;
      }

      const { data, error } = await query;
      if (error) throw error;
      
      setTemplates(data || []);
      
      // If there's a template_name set, select that template
      if (editingTask.template_name) {
        const template = data?.find(t => t.name === editingTask.template_name);
        if (template) {
          setSelectedTemplate(template);
        }
      }
    } catch (err) {
      console.error('Error fetching templates:', err);
      setError('Failed to load templates');
    }
  };

  const handleTemplateSelect = (templateId) => {
    const template = templates.find(t => t.id === templateId);
    setSelectedTemplate(template);
    
    if (template) {
      handleInputChange('template_name', template.name);
    }
  };

  const handleInputChange = (field, value) => {
    setEditingTask(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const validateForm = () => {
    const requiredFields = ['name', 'task_type', 'day_number', 'scheduled_date', 'status'];
    return requiredFields.every(field => {
      const value = editingTask[field];
      return value !== undefined && value !== null && value !== '';
    });
  };

  const renderTemplatePreview = () => {
    if (!selectedTemplate) {
      return (
        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4 text-center">
          <p className="text-gray-500 dark:text-gray-400">
            Select a template to preview the message
          </p>
        </div>
      );
    }

    return selectedTemplate.format === 'HTML' ? (
      <TemplateHTMLPreview
        template={selectedTemplate}
        contact={contact}
        currentUser={currentUser}
      />
    ) : (
      <TemplatePreview
        template={selectedTemplate}
        contact={contact}
        currentUser={currentUser}
      />
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-3xl my-8">
        {/* Modal Header */}
        <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            {editingTask?.id ? 'Edit Task' : 'Create Task'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Error Display */}
        {(error || parentError) && (
          <div className="p-4 bg-red-50 dark:bg-red-900/50 text-red-800 dark:text-red-200 flex items-start gap-2">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <p>{error || parentError}</p>
          </div>
        )}

        {/* Task Details */}
        <div className="p-4 grid grid-cols-2 gap-4">
          <div className="space-y-4">
            {/* Basic Task Info */}
            <div>
              <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                Task Name*
              </label>
              <input
                type="text"
                value={editingTask?.name || ''}
                onChange={(e) => handleInputChange('name', e.target.value)}
                className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                placeholder="Enter task name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                Task Type*
              </label>
              <select
                value={editingTask?.task_type || ''}
                onChange={(e) => handleInputChange('task_type', e.target.value)}
                className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
              >
                <option value="">Select type</option>
                {Object.entries(TASK_TYPES).map(([type, info]) => (
                  <option key={type} value={type}>{info.name}</option>
                ))}
              </select>
            </div>

            {/* Timeline Selection */}
            {!isCustomTask ? (
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                  Timeline Day*
                </label>
                <select
                  value={editingTask?.day_number || 1}
                  onChange={(e) => handleInputChange('day_number', parseInt(e.target.value))}
                  className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                >
                  {TIMELINE_STEPS.map(step => (
                    <option key={step.day} value={step.day}>
                      Day {step.day} - {step.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                  Day Number*
                </label>
                <input
                  type="number"
                  min="1"
                  value={editingTask?.day_number || 1}
                  onChange={(e) => handleInputChange('day_number', parseInt(e.target.value))}
                  className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                Status*
              </label>
              <select
                value={editingTask?.status || TASK_STATUS.PENDING}
                onChange={(e) => handleInputChange('status', e.target.value)}
                className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
              >
                {Object.values(TASK_STATUS).map(status => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                Scheduled Date*
              </label>
              <input
                type="date"
                value={editingTask?.scheduled_date || new Date().toISOString().split('T')[0]}
                onChange={(e) => handleInputChange('scheduled_date', e.target.value)}
                className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>

            {/* Template Selection */}
            {templates.length > 0 && (
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                  Template
                </label>
                <select
                  value={selectedTemplate?.id || ''}
                  onChange={(e) => handleTemplateSelect(e.target.value)}
                  className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                >
                  <option value="">Select a template</option>
                  {templates.map(template => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          {/* Template Preview Section */}
          <div className="space-y-4">
            {renderTemplatePreview()}
          </div>
        </div>

        {/* Modal Footer */}
        <div className="flex justify-between p-4 border-t dark:border-gray-700">
          {editingTask?.id && (
            <button
              onClick={onDelete}
              className="px-4 py-2 text-red-600 hover:text-red-700 font-medium"
              type="button"
            >
              Delete Task
            </button>
          )}
          <div className="flex space-x-2 ml-auto">
            <button
              onClick={onClose}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              type="button"
            >
              Cancel
            </button>
            <button
              onClick={onSave}
              disabled={!validateForm()}
              className={`px-4 py-2 bg-blue-600 text-white rounded-lg ${
                validateForm() 
                  ? 'hover:bg-blue-700' 
                  : 'opacity-50 cursor-not-allowed'
              }`}
              type="button"
            >
              Save Task
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;