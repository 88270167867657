// TaskList.jsx
import React, { useState, useEffect } from 'react';
import { supabase } from '../../config/supabaseConfig';
import TaskCard from './TaskCard';

// TaskList.jsx
const TaskList = ({ tasks = [], onStatusChange, onTaskClick, statusFilter = 'ALL' }) => {
  const [marketingPlanDays, setMarketingPlanDays] = useState(new Set());

  useEffect(() => {
    fetchMarketingPlanDays();
  }, []);

  const fetchMarketingPlanDays = async () => {
    try {
      const { data, error } = await supabase
        .from('marketing_plan_tasks')
        .select('day_number')
        .eq('is_active', true);
      
      if (error) throw error;
      setMarketingPlanDays(new Set(data.map(task => task.day_number)));
    } catch (err) {
      console.error('Error fetching marketing plan days:', err);
    }
  };

  // Get additional tasks (those not in the marketing plan)
  // Note: Now we're not filtering out tasks with the same day number
  const additionalTasks = tasks
    .filter(task => !marketingPlanDays.has(task.day_number))
    .sort((a, b) => {
      // Sort by date first
      const dateComparison = new Date(a.scheduled_date) - new Date(b.scheduled_date);
      if (dateComparison !== 0) return dateComparison;
      // If dates are equal, sort by day number
      return a.day_number - b.day_number;
    });

  // Filter tasks based on status
  const filteredTasks = statusFilter === 'ALL' 
    ? additionalTasks 
    : additionalTasks.filter(task => task.status === statusFilter);

  if (filteredTasks.length === 0) return null;

  // Group tasks by day
  const tasksByDay = filteredTasks.reduce((acc, task) => {
    const day = task.day_number;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(task);
    return acc;
  }, {});

  return (
    <div className="border-t dark:border-gray-700 p-4 space-y-6">
      <div className="space-y-2">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          Additional Tasks
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Custom tasks outside the standard timeline
        </p>
      </div>

      {/* Task Grid */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {Object.entries(tasksByDay).map(([day, dayTasks]) => (
          <div key={day} className="space-y-4">
            <div className="font-medium text-sm text-gray-500 dark:text-gray-400">
              Day {day} {dayTasks.length > 1 && `(${dayTasks.length} tasks)`}
            </div>
            {dayTasks.map((task, index) => (
              <TaskCard
                key={task.id}
                task={task}
                onStatusChange={onStatusChange}
                onViewDetails={() => onTaskClick(task)}
                isAdditional={index > 0}
              />
            ))}
          </div>
        ))}
      </div>

      {/* Empty State (when filtered) */}
      {additionalTasks.length > 0 && filteredTasks.length === 0 && (
        <div className="text-center py-6">
          <p className="text-gray-500 dark:text-gray-400">
            No {statusFilter.toLowerCase()} tasks found.
          </p>
        </div>
      )}
    </div>
  );
};

export default TaskList;