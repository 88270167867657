import { supabase } from '../config/supabaseConfig';
import { TASK_STATUS } from '../config/constants';

/**
 * Creates tasks for a contact based on marketing plan tasks
 * @param {string} contactId The ID of the contact to create tasks for
 */
export const createTasksForContact = async (contactId) => {
  try {
    // Fetch all active marketing plan tasks
    const { data: marketingPlanTasks, error: planError } = await supabase
      .from('marketing_plan_tasks')
      .select('*')
      .eq('is_active', true)
      .order('sort_order');

    if (planError) throw planError;

    // Fetch template mappings for these tasks
    const { data: templateMappings, error: mappingError } = await supabase
      .from('template_task_mappings')
      .select(`
        *,
        marketing_plan_tasks!inner (id),
        templates!inner (
          id,
          name,
          type
        )
      `);

    if (mappingError) throw mappingError;

    // Create a map of task IDs to their templates
    const templateMap = templateMappings.reduce((acc, mapping) => {
      acc[mapping.task_id] = mapping.templates;
      return acc;
    }, {});

    // Calculate dates and create task records
    const today = new Date();
    const taskRecords = marketingPlanTasks.map(planTask => {
      const scheduledDate = new Date(today);
      scheduledDate.setDate(today.getDate() + planTask.day_number);

      // Get the mapped template if one exists
      const template = templateMap[planTask.id];

      return {
        contact_id: contactId,
        task_type: planTask.task_type,
        name: planTask.name,
        day_number: planTask.day_number,
        scheduled_date: scheduledDate.toISOString(),
        status: TASK_STATUS.PENDING,
        template_name: template?.name || null,
        completed_date: null,
        notes: null
      };
    });

    // Insert all tasks
    const { error: insertError } = await supabase
      .from('tasks')
      .insert(taskRecords);

    if (insertError) throw insertError;

    return taskRecords;
  } catch (error) {
    console.error('Error creating tasks for contact:', error);
    throw error;
  }
};

/**
 * Updates a task and handles any necessary side effects
 * @param {string} taskId The ID of the task to update
 * @param {Object} updates The updates to apply to the task
 */
export const updateTask = async (taskId, updates) => {
  try {
    const { data, error } = await supabase
      .from('tasks')
      .update(updates)
      .eq('id', taskId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating task:', error);
    throw error;
  }
};