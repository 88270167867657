import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Mail, 
  Linkedin, 
  Phone,  
  Calendar,
  Filter,
  Search,
  AlertCircle,
  Users
} from 'lucide-react';
import { supabase } from '../../config/supabaseConfig';
import { TASK_STATUS, TASK_TYPES } from '../../config/constants';
import TaskActionButton from './TaskActionButton';

const TaskOverview = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [typeFilter, setTypeFilter] = useState('ALL');
  const [teamMemberFilter, setTeamMemberFilter] = useState('ALL');
  const [searchTerm, setSearchTerm] = useState('');
  const [taskStats, setTaskStats] = useState({});

  useEffect(() => {
    Promise.all([fetchTasks(), fetchTeamMembers()]);
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const { data, error } = await supabase
        .from('team_members')
        .select('*')
        .order('first_name');

      if (error) throw error;
      setTeamMembers(data || []);
    } catch (err) {
      console.error('Error fetching team members:', err);
    }
  };

  const fetchTasks = async () => {
    try {
      setLoading(true);
      setError(null);
  
      const { data, error } = await supabase
        .from('tasks')
        .select(`
          *,
          contacts (
            id,
            first_name,
            last_name,
            company,
            title,
            person_linkedin_url,
            assigned_to,
            team_members (
              id,
              first_name,
              last_name,
              email
            )
          )
        `)
        .order('scheduled_date', { ascending: true });
  
      if (error) throw error;
  
      // Transform the data to match the expected structure
      const transformedTasks = data?.map(task => ({
        ...task,
        contact: task.contacts  // Rename contacts to contact
      })) || [];
  
      setTasks(transformedTasks);  // Store transformed data
      calculateTaskStats(transformedTasks);  // Pass transformed data
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setError('Failed to load tasks. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const calculateTaskStats = (taskData) => {
    const stats = {
      total: taskData.length,
      overdue: 0,
      dueToday: 0,
      byStatus: {},
      byType: {},
      byTeamMember: {}
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    taskData.forEach(task => {
      // Count by status
      stats.byStatus[task.status] = (stats.byStatus[task.status] || 0) + 1;
      
      // Count by type
      stats.byType[task.task_type] = (stats.byType[task.task_type] || 0) + 1;

      // Count by team member
      const teamMemberId = task.contacts?.assigned_to;
      if (teamMemberId) {
        stats.byTeamMember[teamMemberId] = (stats.byTeamMember[teamMemberId] || 0) + 1;
      }

      // Check due dates
      const taskDate = new Date(task.scheduled_date);
      taskDate.setHours(0, 0, 0, 0);

      if (taskDate.getTime() === today.getTime()) {
        stats.dueToday++;
      } else if (taskDate < today && task.status === TASK_STATUS.PENDING) {
        stats.overdue++;
      }
    });

    setTaskStats(stats);
  };

  const getFilteredTasks = () => {
    return tasks.filter(task => {
      const matchesStatus = statusFilter === 'ALL' || task.status === statusFilter;
      const matchesType = typeFilter === 'ALL' || task.task_type === typeFilter;
      const matchesTeamMember = teamMemberFilter === 'ALL' || task.contacts?.assigned_to === teamMemberFilter;
      const matchesSearch = searchTerm === '' || 
        task.contacts?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.contacts?.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.contacts?.company?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.name?.toLowerCase().includes(searchTerm.toLowerCase());

      return matchesStatus && matchesType && matchesTeamMember && matchesSearch;
    });
  };

  // Group tasks by date sections
  const getGroupedTasks = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const groups = {
      overdue: [],
      today: [],
      tomorrow: [],
      thisWeek: [],
      later: []
    };

    const filteredTasks = getFilteredTasks();
    
    filteredTasks.forEach(task => {
      const taskDate = new Date(task.scheduled_date);
      taskDate.setHours(0, 0, 0, 0);
      
      if (taskDate < today && task.status === TASK_STATUS.PENDING) {
        groups.overdue.push(task);
      } else if (taskDate.getTime() === today.getTime()) {
        groups.today.push(task);
      } else if (taskDate.getTime() === today.getTime() + 86400000) {
        groups.tomorrow.push(task);
      } else if (taskDate <= new Date(today.getTime() + 6 * 86400000)) {
        groups.thisWeek.push(task);
      } else {
        groups.later.push(task);
      }
    });

    return groups;
  };

  const getTaskTypeIcon = (type) => {
    switch (type) {
      case 'EMAIL':
        return <Mail className="h-5 w-5" />;
      case 'LINKEDIN':
        return <Linkedin className="h-5 w-5" />;
      case 'CALL':
        return <Phone className="h-5 w-5" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case TASK_STATUS.COMPLETED:
        return 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300';
      case TASK_STATUS.IN_PROGRESS:
        return 'bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-300';
      case TASK_STATUS.FAILED:
        return 'bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-300';
      case TASK_STATUS.SKIPPED:
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-300';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300';
    }
  };

  const renderTaskGroup = (tasks, title, isOverdue = false) => {
    if (tasks.length === 0) return null;

    return (
      <div className="mb-8">
        <h3 className={`text-lg font-medium mb-4 ${
          isOverdue ? 'text-red-600 dark:text-red-400' : 'text-gray-900 dark:text-white'
        }`}>
          {title} ({tasks.length})
        </h3>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
          <table className="w-full">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Task
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Assigned To
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Due Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {tasks.map(task => (
                <tr 
                  key={task.id}
                  className={`hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer ${
                    isOverdue ? 'bg-red-50 dark:bg-red-900/20' : ''
                  }`}
                  onClick={() => window.open(`/marketing-tracker/contacts/${task.contact_id}/tasks`, '_blank')}
                >
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {task.name}
                    </div>
                    {task.template_name && (
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        Template: {task.template_name}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {task.contacts?.first_name} {task.contacts?.last_name}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      {task.contacts?.company}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900 dark:text-white">
                      {task.contacts?.team_members ? 
                        `${task.contacts.team_members.first_name} ${task.contacts.team_members.last_name}` :
                        'Unassigned'
                      }
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                      <div className="text-sm text-gray-900 dark:text-white">
                        {new Date(task.scheduled_date).toLocaleDateString()}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className={`px-2 py-1 text-xs rounded-full ${getStatusColor(task.status)}`}>
                      {task.status}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-gray-500 dark:text-gray-400">
                      {getTaskTypeIcon(task.task_type)}
                      <span className="ml-2">{TASK_TYPES[task.task_type]?.name}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <TaskActionButton task={task} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
      </div>
    );
  }

  const groupedTasks = getGroupedTasks();

  return (
    <div className="space-y-6">
      {/* Header with Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Total Tasks */}
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Tasks</h3>
          <p className="text-2xl font-bold mt-1">{taskStats.total || 0}</p>
        </div>

        {/* Overdue Tasks */}
        <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg shadow">
          <h3 className="text-sm font-medium text-red-800 dark:text-red-200">Overdue</h3>
          <p className="text-2xl font-bold text-red-800 dark:text-red-200 mt-1">
            {taskStats.overdue || 0}
          </p>
        </div>

        {/* Due Today */}
        <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg shadow">
          <h3 className="text-sm font-medium text-blue-800 dark:text-blue-200">Due Today</h3>
          <p className="text-2xl font-bold text-blue-800 dark:text-blue-200 mt-1">
            {taskStats.dueToday || 0}
          </p>
        </div>

        {/* Completion Rate */}
        <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg shadow">
          <h3 className="text-sm font-medium text-green-800 dark:text-green-200">Completion Rate</h3>
          <p className="text-2xl font-bold text-green-800 dark:text-green-200 mt-1">
            {taskStats.total ? 
              Math.round((taskStats.byStatus?.[TASK_STATUS.COMPLETED] || 0) / taskStats.total * 100) 
              : 0}%
          </p>
        </div>
      </div>

      {/* Filters and Search */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
            <input
              type="text"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>

          <div className="flex flex-col md:flex-row gap-4">
            {/* Team Member Filter */}
            <div className="flex items-center gap-2">
              <Users className="h-4 w-4 text-gray-400" />
              <select
                value={teamMemberFilter}
                onChange={(e) => setTeamMemberFilter(e.target.value)}
                className="border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600"
              >
                <option value="ALL">All Team Members</option>
                {teamMembers.map(member => (
                  <option key={member.id} value={member.id}>
                    {member.first_name} {member.last_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Status Filter */}
            <div className="flex items-center gap-2">
              <Filter className="h-4 w-4 text-gray-400" />
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600"
              >
                <option value="ALL">All Status</option>
                {Object.values(TASK_STATUS).map(status => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
            </div>

            {/* Task Type Filter */}
            <select
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              className="border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600"
            >
              <option value="ALL">All Types</option>
              {Object.entries(TASK_TYPES).map(([type, info]) => (
                <option key={type} value={type}>{info.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

            {/* Task Groups */}
            {renderTaskGroup(groupedTasks.overdue, 'Overdue Tasks', true)}
      {renderTaskGroup(groupedTasks.today, 'Due Today')}
      {renderTaskGroup(groupedTasks.tomorrow, 'Due Tomorrow')}
      {renderTaskGroup(groupedTasks.thisWeek, 'Due This Week')}
      {renderTaskGroup(groupedTasks.later, 'Due Later')}

      {/* Empty State */}
      {getFilteredTasks().length === 0 && (
        <div className="text-center py-12 bg-white dark:bg-gray-800 rounded-lg shadow">
          <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">No tasks found</h3>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Try adjusting your search or filter criteria
          </p>
        </div>
      )}
    </div>
  );
};

export default TaskOverview;