import React from 'react';
import { 
  Mail, 
  Linkedin, 
  Phone, 
  CheckCircle, 
  Clock, 
  XCircle, 
  AlertCircle, 
  ChevronRight, 
  FileText,
  Calendar 
} from 'lucide-react';
import { TASK_STATUS, TASK_TYPES } from '../../config/constants';
import TaskActionButton from './TaskActionButton';

const TaskCard = ({ task, contact, onStatusChange, onViewDetails, isAdditional = false }) => {
  const getTaskIcon = (type) => {
    switch (type) {
      case 'EMAIL':
        return <Mail className="h-5 w-5" />;
      case 'LINKEDIN':
        return <Linkedin className="h-5 w-5" />;
      case 'CALL':
        return <Phone className="h-5 w-5" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case TASK_STATUS.COMPLETED:
        return 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300 border-green-500';
      case TASK_STATUS.IN_PROGRESS:
        return 'bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-300 border-blue-500';
      case TASK_STATUS.FAILED:
        return 'bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-300 border-red-500';
      case TASK_STATUS.SKIPPED:
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-300 border-yellow-500';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300 border-gray-300 dark:border-gray-600';
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case TASK_STATUS.COMPLETED:
        return <CheckCircle className="h-5 w-5" />;
      case TASK_STATUS.IN_PROGRESS:
        return <Clock className="h-5 w-5" />;
      case TASK_STATUS.FAILED:
        return <XCircle className="h-5 w-5" />;
      case TASK_STATUS.SKIPPED:
        return <AlertCircle className="h-5 w-5" />;
      default:
        return <Clock className="h-5 w-5" />;
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  // Check if task is overdue
  const isOverdue = (dateString, status) => {
    if (status === TASK_STATUS.COMPLETED || status === TASK_STATUS.SKIPPED) {
      return false;
    }
    try {
      const taskDate = new Date(dateString);
      return taskDate < new Date();
    } catch (error) {
      return false;
    }
  };

  const taskOverdue = isOverdue(task.scheduled_date, task.status);

  return (
    <div 
      className={`
        bg-white dark:bg-gray-800 rounded-lg shadow-sm 
        border dark:border-gray-700 p-4
        ${taskOverdue ? 'border-l-4 border-l-red-500' : ''}
        ${isAdditional ? 'border-t-4 border-t-blue-500' : ''}
        transition-all duration-200
        relative
      `}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onViewDetails(task);
        }
      }}
    >
      {isAdditional && (
        <div className="absolute top-2 right-2">
          <span className="px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-200 rounded-full">
            Additional Task
          </span>
        </div>
      )}

      <div className="flex items-start justify-between">
        {/* Task Info */}
        <div className="flex items-start space-x-3">
          <div className={`
            p-2 rounded-lg
            ${getStatusColor(task.status)}
            transition-colors duration-200
          `}>
            {getTaskIcon(task.task_type)}
          </div>
          
          <div>
            <h3 className="font-medium text-gray-900 dark:text-white flex items-center gap-2">
              {task.name || TASK_TYPES[task.task_type].name}
            </h3>
            <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
              <Calendar className="h-4 w-4" />
              <span>Day {task.day_number} - {formatDate(task.scheduled_date)}</span>
              {taskOverdue && (
                <span className="px-2 py-0.5 bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-200 rounded-full text-xs">
                  Overdue
                </span>
              )}
            </div>
            
            {/* Template Info */}
            {task.template_name && (
              <div className="flex items-center mt-1 text-sm text-gray-500 dark:text-gray-400">
                <FileText className="h-4 w-4 mr-1" />
                {task.template_name}
              </div>
            )}
            
            {task.notes && (
              <p className="text-sm text-gray-600 dark:text-gray-300 mt-2 italic">
                "{task.notes}"
              </p>
            )}
          </div>
        </div>

        {/* Status Badge & Task Action */}
        <div className="flex flex-col items-end gap-2">
          <span className={`
            inline-flex items-center px-2.5 py-0.5 
            rounded-full text-xs font-medium
            ${getStatusColor(task.status)}
          `}>
            <span className="mr-1">{getStatusIcon(task.status)}</span>
            {task.status}
          </span>

          <TaskActionButton task={task} />
        </div>
      </div>

      {/* Actions */}
      <div 
        className="mt-4 flex items-center justify-between"
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <select
            value={task.status}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation();
              onStatusChange(task.id, e.target.value);
            }}
            className={`
              text-sm border rounded-md px-2 py-1 
              dark:bg-gray-700 dark:border-gray-600
              transition-colors duration-200
              ${taskOverdue && task.status === TASK_STATUS.PENDING ? 'border-red-300' : ''}
            `}
          >
            {Object.values(TASK_STATUS).map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
        </div>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onViewDetails(task);
          }}
          className="flex items-center text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
        >
          View Details
          <ChevronRight className="h-4 w-4 ml-1" />
        </button>
      </div>  
    </div>
  );
};

export default TaskCard;