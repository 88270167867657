import React, { useState } from 'react';
import { Copy, CheckCircle } from 'lucide-react';

const TemplatePreview = ({ template, contact, currentUser }) => {
  const [copied, setCopied] = useState(false);

  const fillTemplate = () => {
    if (!template?.content) return '';

    let filledContent = template.content;
    try {
      if (contact) {
        // Replace contact variables
        filledContent = filledContent
          .replace(/\[contact\.first_name\]/g, contact.first_name || '')
          .replace(/\[contact\.last_name\]/g, contact.last_name || '')
          .replace(/\[contact\.full_name\]/g, `${contact.first_name || ''} ${contact.last_name || ''}`)
          .replace(/\[contact\.title\]/g, contact.title || '')
          .replace(/\[contact\.company\]/g, contact.company || '')
          .replace(/\[contact\.email\]/g, contact.email || '')
          .replace(/\[contact\.industry\]/g, contact.industry || '')
          .replace(/\[contact\.city\]/g, contact.city || '')
          .replace(/\[contact\.state\]/g, contact.state || '')
          .replace(/\[contact\.country\]/g, contact.country || '');

        // Replace company variables
        filledContent = filledContent
          .replace(/\[company\.name\]/g, contact.company || '')
          .replace(/\[company\.address\]/g, contact.company_address || '')
          .replace(/\[company\.city\]/g, contact.company_city || '')
          .replace(/\[company\.state\]/g, contact.company_state || '')
          .replace(/\[company\.country\]/g, contact.company_country || '')
          .replace(/\[company\.phone\]/g, contact.company_phone || '');
      }

      // Replace team member variables if available
      if (contact?.team_members) {
        const teamMember = contact.team_members;
        filledContent = filledContent
          .replace(/\[team_member\.first_name\]/g, teamMember.first_name || '')
          .replace(/\[team_member\.last_name\]/g, teamMember.last_name || '')
          .replace(/\[team_member\.full_name\]/g, `${teamMember.first_name || ''} ${teamMember.last_name || ''}`)
          .replace(/\[team_member\.email\]/g, teamMember.email || '')
          .replace(/\[team_member\.title\]/g, teamMember.title || '');
      }

      // Replace any remaining variables with placeholders
      filledContent = filledContent.replace(/\[(.*?)\]/g, (match, variable) => `<${variable}>`);

      return filledContent;
    } catch (err) {
      console.error('Error filling template:', err);
      return template.content;
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(fillTemplate());
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Error copying template:', err);
    }
  };

  return (
    <div className="space-y-4">
      {/* Preview Header */}
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          Message Preview
        </h3>
        <button
          onClick={handleCopy}
          className={`flex items-center px-3 py-1 rounded-lg transition-colors ${
            copied
              ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-200'
              : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
          }`}
        >
          {copied ? (
            <>
              <CheckCircle className="h-4 w-4 mr-1" />
              Copied!
            </>
          ) : (
            <>
              <Copy className="h-4 w-4 mr-1" />
              Copy
            </>
          )}
        </button>
      </div>

      {/* Message Content */}
      <div className="bg-white dark:bg-gray-800 rounded-lg border dark:border-gray-700 p-4">
        <div className="whitespace-pre-wrap text-gray-900 dark:text-white font-mono">
          {fillTemplate()}
        </div>
      </div>
    </div>
  );
};

export default TemplatePreview;