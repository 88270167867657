import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../../config/supabaseConfig';
import { AlertCircle, Loader } from 'lucide-react';

const AcceptInvite = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const inviteToken = searchParams.get('token');
    const type = searchParams.get('type');

    if (type === 'invite' && inviteToken) {
      setToken(inviteToken);
      setLoading(false);
    } else {
      // Check URL parameters for a token
      const urlParams = new URLSearchParams(location.search);
      const urlToken = urlParams.get('token');
      if (urlToken) {
        setToken(urlToken);
        setLoading(false);
      } else {
        setError('Invalid invite link');
        setLoading(false);
      }
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }

    try {
      setLoading(true);
      setError('');

      // Update user's password using the token
      const { data, error: updateError } = await supabase.auth.updateUser({
        password: password
      });

      if (updateError) throw updateError;

      // Sign in with the new password
      const { data: session, error: signInError } = await supabase.auth.getSession();
      
      if (signInError) throw signInError;

      navigate('/dashboard');
    } catch (err) {
      console.error('Error setting password:', err);
      setError(err.message || 'An error occurred while setting the password');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <Loader className="h-8 w-8 animate-spin text-blue-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full">
        <div className="text-center mb-8">
          <img 
            src="https://www.verilo.co/veriloLogo512.png"
            alt="Verilo Logo"
            className="h-12 w-auto mx-auto mb-4"
          />
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            Set Your Password
          </h2>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            Please set a password to complete your account setup
          </p>
        </div>

        {error && (
          <div className="mb-6 bg-red-50 dark:bg-red-900/50 text-red-800 dark:text-red-200 p-3 rounded-md flex items-center gap-2">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <p>{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              New Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
              placeholder="Enter your new password"
              required
              minLength={6}
            />
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Password must be at least 6 characters long
            </p>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <Loader className="h-5 w-5 animate-spin" />
            ) : (
              'Set Password & Continue'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AcceptInvite;