import React, { useState } from 'react';
import { ExternalLink, Copy, Eye, CheckCircle } from 'lucide-react';

const TemplateHTMLPreview = ({ template, contact, currentUser }) => {
  const [copied, setCopied] = useState(false);

  const fillTemplate = () => {
    if (!template?.html_template) return '';
    
    let filledContent = template.html_template;

    try {
      if (contact) {
        // Replace contact variables
        filledContent = filledContent
          .replace(/\[contact\.first_name\]/g, contact.first_name || '')
          .replace(/\[contact\.last_name\]/g, contact.last_name || '')
          .replace(/\[contact\.full_name\]/g, `${contact.first_name || ''} ${contact.last_name || ''}`)
          .replace(/\[contact\.title\]/g, contact.title || '')
          .replace(/\[contact\.company\]/g, contact.company || '')
          .replace(/\[contact\.email\]/g, contact.email || '')
          .replace(/\[contact\.industry\]/g, contact.industry || '')
          .replace(/\[contact\.city\]/g, contact.city || '')
          .replace(/\[contact\.state\]/g, contact.state || '')
          .replace(/\[contact\.country\]/g, contact.country || '');

        // Replace company variables
        filledContent = filledContent
          .replace(/\[company\.name\]/g, contact.company || '')
          .replace(/\[company\.address\]/g, contact.company_address || '')
          .replace(/\[company\.city\]/g, contact.company_city || '')
          .replace(/\[company\.state\]/g, contact.company_state || '')
          .replace(/\[company\.country\]/g, contact.company_country || '')
          .replace(/\[company\.phone\]/g, contact.company_phone || '');
      }

      // Replace team member variables if available
      if (contact?.team_members) {
        const teamMember = contact.team_members;
        filledContent = filledContent
          .replace(/\[team_member\.first_name\]/g, teamMember.first_name || '')
          .replace(/\[team_member\.last_name\]/g, teamMember.last_name || '')
          .replace(/\[team_member\.full_name\]/g, `${teamMember.first_name || ''} ${teamMember.last_name || ''}`)
          .replace(/\[team_member\.email\]/g, teamMember.email || '')
          .replace(/\[team_member\.title\]/g, teamMember.title || '');
      }

      // Replace any remaining variables with placeholders
      filledContent = filledContent.replace(/\[(.*?)\]/g, (match, variable) => `<${variable}>`);

      return filledContent;
    } catch (err) {
      console.error('Error filling template:', err);
      return template.html_template;
    }
  };

  const openInNewTab = () => {
    const filledContent = fillTemplate();
    const blob = new Blob([filledContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  const copyToClipboard = async () => {
    try {
      const filledContent = fillTemplate();
      await navigator.clipboard.writeText(filledContent);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Error copying template:', err);
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-end space-x-2">
        <button
          onClick={openInNewTab}
          className="inline-flex items-center px-3 py-1 text-sm bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-200 rounded-lg hover:bg-blue-200 dark:hover:bg-blue-800/50"
        >
          <Eye className="h-4 w-4 mr-1" />
          Preview
        </button>
        <button
          onClick={copyToClipboard}
          className={`inline-flex items-center px-3 py-1 text-sm rounded-lg transition-colors ${
            copied
              ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-200'
              : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
          }`}
        >
          {copied ? (
            <>
              <CheckCircle className="h-4 w-4 mr-1" />
              Copied!
            </>
          ) : (
            <>
              <Copy className="h-4 w-4 mr-1" />
              Copy HTML
            </>
          )}
        </button>
      </div>
      
      <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
        <iframe
          srcDoc={fillTemplate()}
          title="Email Preview"
          className="w-full h-96 border-0 rounded bg-white"
        />
      </div>
    </div>
  );
};

export default TemplateHTMLPreview;