import React, { useState } from 'react';
import { LogOut } from 'lucide-react';  // Add this to your existing imports
import { useAuth } from '../../context/AuthContext';  // Add this line
import { Users, Mail, Phone, FileSpreadsheet, Settings, Menu, X, Target, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const DashboardLayout = ({ children }) => {
 console.log('DashboardLayout rendering...', { children });
 const [isSidebarOpen, setSidebarOpen] = useState(true);
 const navigate = useNavigate();
 const { signOut, user } = useAuth();

 const primaryNavItems = [
   { name: 'Dashboard', icon: LayoutDashboard, path: '/dashboard' },
   { name: 'Contacts', icon: Users, path: '/contacts' },
   { name: 'Tasks', icon: Phone, path: '/tasks' }
 ];

 const secondaryNavItems = [
   { name: 'Templates', icon: Mail, path: '/templates' },
   { name: 'Marketing', icon: Target, path: '/marketing' },
   { name: 'Import CSV', icon: FileSpreadsheet, path: '/import' },
  //  { name: 'Settings', icon: Settings, path: '/settings' }
 ];

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      {/* Mobile Sidebar Toggle */}
      <div className="lg:hidden fixed top-4 left-4 z-50">
        <button
          onClick={() => setSidebarOpen(!isSidebarOpen)}
          className="p-2 rounded-lg bg-white dark:bg-gray-800 shadow-lg"
        >
          {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Sidebar */}
      <aside
        className={`fixed inset-y-0 left-0 z-40 w-64 transform bg-white dark:bg-gray-800 shadow-lg transition-transform duration-150 ease-in-out ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
      >
        {/* Logo */}
        <div className="h-16 flex items-center justify-center gap-3 border-b dark:border-gray-700 px-4">
          <img 
            src="https://www.verilo.co/veriloLogo512.png"
            alt="Verilo Logo"
            className="h-8 w-8 object-contain"
          />
          <h1 className="text-xl font-bold text-gray-800 dark:text-white">Verilo Outreach</h1>
        </div>

        {/* Navigation */}
        <nav className="mt-6 px-4">
          {/* Primary Navigation */}
          {primaryNavItems.map((item) => (
            <button
              key={item.name}
              onClick={() => navigate(item.path)}
              className="w-full mt-2 flex items-center px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <item.icon className="h-5 w-5" />
              <span className="ml-3">{item.name}</span>
            </button>
          ))}

          {/* Divider */}
          <div className="my-4">
            <div className="border-t border-gray-200 dark:border-gray-700"></div>
          </div>

          {/* Secondary Navigation */}
          {secondaryNavItems.map((item) => (
            <button
              key={item.name}
              onClick={() => navigate(item.path)}
              className="w-full mt-2 flex items-center px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <item.icon className="h-5 w-5" />
              <span className="ml-3">{item.name}</span>
            </button>
          ))}
        </nav>

        {/* User Info and Sign Out (Added at bottom of sidebar) */}
        <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex flex-col space-y-2">
            <div className="text-sm text-gray-600 dark:text-gray-400 truncate">
              {user?.email}
            </div>
            <button
              onClick={signOut}
              className="flex items-center px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg transition-colors"
            >
              <LogOut className="h-4 w-4 mr-2" />
              Sign Out
            </button>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <main
        className={`transition-all duration-150 ease-in-out ${
          isSidebarOpen ? 'lg:ml-64' : ''
        }`}
      >
        {/* Top Bar with User Info for Mobile (Only visible on mobile) */}
        <div className="lg:hidden bg-white dark:bg-gray-800 shadow-sm p-4 mb-4">
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-600 dark:text-gray-400 truncate">
              {user?.email}
            </div>
            <button
              onClick={signOut}
              className="text-sm text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-300"
            >
              Sign Out
            </button>
          </div>
        </div>

        <div className="container mx-auto px-4 py-8">
          {/* Content */}
          {children}
        </div>
      </main>
    </div>
  );
};

export default DashboardLayout;