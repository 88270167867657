import React, { useState, useEffect } from 'react';
import { Mail, Linkedin, Phone, FileText, Copy, CheckCircle, X, Loader } from 'lucide-react';
import { supabase } from '../../config/supabaseConfig';

const TaskActionButton = ({ task }) => {
  const [template, setTemplate] = useState(null);
  const [copied, setCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Only fetch if we have a template name and haven't already loaded the template
    if (task.template_name && !template && !loading) {
      console.log('TaskActionButton: Initializing with task:', {
        templateName: task.template_name,
        taskType: task.task_type,
        contact: task.contact
      });
      fetchTemplate();
    }
  }, [task.template_name]);

  // Map task names to actual template names in database
  const getTemplateNameForTask = (taskName) => {
    console.log('Getting template name for:', taskName);
    const templateMap = {
      'LinkedIn Direct Message': 'Regular LinkedIn Message',
      'LinkedIn Connection Request': 'Standard LinkedIn Connection',
      'Initial Email': 'Initial Email',
      'Follow-up Email': 'Verilo Follow-up Email'
    };
    const mapped = templateMap[taskName] || taskName;
    console.log('Mapped template name:', mapped);
    return mapped;
  };

  const fetchTemplate = async () => {
    if (!task.template_name) {
      console.log('No template name provided');
      return;
    }

    const mappedTemplateName = getTemplateNameForTask(task.template_name);
    console.log('Fetching template:', {
      original: task.template_name,
      mapped: mappedTemplateName,
      taskType: task.task_type
    });

    try {
      setLoading(true);
      setError(null);

      let query = supabase
        .from('templates')
        .select('*');

      if (task.task_type === 'LINKEDIN') {
        query = query.in('type', ['LINKEDIN_CONNECTION', 'LINKEDIN_MESSAGE']);
      } else if (task.task_type === 'EMAIL') {
        query = query.eq('type', 'EMAIL');
      }

      let { data, error } = await query
        .eq('name', mappedTemplateName)
        .maybeSingle();

      console.log('Initial template query result:', { data, error });

      if (error) throw error;

      if (!data) {
        console.log('No exact match found, trying case-insensitive search');
        const { data: fuzzyData, error: fuzzyError } = await query
          .ilike('name', mappedTemplateName)
          .maybeSingle();

        if (fuzzyError) throw fuzzyError;
        data = fuzzyData;
        console.log('Fuzzy search result:', data);
      }

      if (data) {
        console.log('Setting template:', data);
        setTemplate(data);
      } else {
        console.log('No template found');
      }
    } catch (err) {
      console.error('Error fetching template:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fillTemplate = () => {
    console.log('Starting template fill:', {
      template,
      taskType: task.task_type,
      contactData: task.contact,
      format: task.task_type === 'EMAIL' ? 'HTML' : 'TEXT'
    });

    if (!template) {
      console.log('No template available');
      return '';
    }
    
    // Select the right content based on type and format
    let filledContent = template.format === 'HTML' ? 
      template.html_template : 
      template.content;

    if (!filledContent) {
      console.log('No content in template');
      return '';
    }

    console.log('Initial template content:', filledContent);

    try {
      if (task.contact) {
        const contact = task.contact;
        
        // Contact variables
        filledContent = filledContent
          .replace(/\[contact\.first_name\]/g, contact.first_name || '')
          .replace(/\[contact\.last_name\]/g, contact.last_name || '')
          .replace(/\[contact\.full_name\]/g, `${contact.first_name || ''} ${contact.last_name || ''}`)
          .replace(/\[contact\.title\]/g, contact.title || '')
          .replace(/\[contact\.company\]/g, contact.company || '')
          .replace(/\[contact\.email\]/g, contact.email || '')
          .replace(/\[contact\.industry\]/g, contact.industry || '')
          .replace(/\[contact\.city\]/g, contact.city || '')
          .replace(/\[contact\.state\]/g, contact.state || '')
          .replace(/\[contact\.country\]/g, contact.country || '');

        // Company variables
        filledContent = filledContent
          .replace(/\[company\.name\]/g, contact.company || '')
          .replace(/\[company\.address\]/g, contact.company_address || '')
          .replace(/\[company\.city\]/g, contact.company_city || '')
          .replace(/\[company\.state\]/g, contact.company_state || '')
          .replace(/\[company\.country\]/g, contact.company_country || '')
          .replace(/\[company\.phone\]/g, contact.company_phone || '');

        // Team member variables
        if (contact.team_members) {
          const teamMember = contact.team_members;
          filledContent = filledContent
            .replace(/\[team_member\.first_name\]/g, teamMember.first_name || '')
            .replace(/\[team_member\.last_name\]/g, teamMember.last_name || '')
            .replace(/\[team_member\.full_name\]/g, `${teamMember.first_name || ''} ${teamMember.last_name || ''}`)
            .replace(/\[team_member\.email\]/g, teamMember.email || '')
            .replace(/\[team_member\.title\]/g, teamMember.title || '');
        }

        // Replace any remaining variables with placeholders
        filledContent = filledContent.replace(/\[(.*?)\]/g, (match, variable) => `<${variable}>`);
      }

      console.log('Final filled content:', filledContent);
      return filledContent;
    } catch (err) {
      console.error('Error filling template:', err);
      return filledContent;
    }
  };

  const handleEmailAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!template) return;
    
    console.log('Handling email action');
    const filledContent = fillTemplate();
    const win = window.open('', '_blank');
    win.document.write(filledContent);
    win.document.close();
  };

  const handleLinkedInAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    console.log('Handling LinkedIn action:', {
      hasTemplate: !!template,
      contact: task.contact,
      linkedInUrl: task.contact?.person_linkedin_url
    });
    
    if (template) {
      setShowModal(true);
    }
    
    if (task.contact?.person_linkedin_url) {
      window.open(task.contact.person_linkedin_url, '_blank');
    }
  };

  const handlePhoneAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  };

  const handlePlainTextAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  };

  const copyToClipboard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const filledContent = fillTemplate();
    try {
      await navigator.clipboard.writeText(filledContent);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
        setShowModal(false);
      }, 2000);
    } catch (err) {
      console.error('Error copying to clipboard:', err);
      setError('Failed to copy to clipboard');
    }
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(false);
    setError(null);
  };

  // Debug log for render state
  console.log('TaskActionButton render:', {
    taskType: task.task_type,
    hasTemplate: !!template,
    hasLinkedInUrl: !!task.contact?.person_linkedin_url,
    isLoading: loading,
    contactData: task.contact
  });

  // Determine which button to show based on task type
  const renderActionButton = () => {
    switch (task.task_type) {
      case 'EMAIL':
        return (
          <button
            onClick={handleEmailAction}
            disabled={!template || loading}
            className="inline-flex items-center px-3 py-1 rounded text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <Loader className="h-4 w-4 mr-1 animate-spin" />
            ) : (
              <Mail className="h-4 w-4 mr-1" />
            )}
            View Email
          </button>
        );
      
      case 'LINKEDIN':
        return (
          <button
            onClick={handleLinkedInAction}
            disabled={!task.contact?.person_linkedin_url || loading}
            className="inline-flex items-center px-3 py-1 rounded text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <Loader className="h-4 w-4 mr-1 animate-spin" />
            ) : (
              <Linkedin className="h-4 w-4 mr-1" />
            )}
            View Message {template && '& Open'}
          </button>
        );

      case 'CALL':
        return (
          <button
            onClick={handlePhoneAction}
            disabled={!template || loading}
            className="inline-flex items-center px-3 py-1 rounded text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <Loader className="h-4 w-4 mr-1 animate-spin" />
            ) : (
              <Phone className="h-4 w-4 mr-1" />
            )}
            View Script
          </button>
        );

      default:
        if (template) {
          return (
            <button
              onClick={handlePlainTextAction}
              disabled={loading}
              className="inline-flex items-center px-3 py-1 rounded text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? (
                <Loader className="h-4 w-4 mr-1 animate-spin" />
              ) : (
                <FileText className="h-4 w-4 mr-1" />
              )}
              View Message
            </button>
          );
        }
        return null;
    }
  };

  return (
    <>
      {renderActionButton()}

      {showModal && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          onClick={handleModalClose}
        >
          <div 
            className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-lg p-6 relative"
            onClick={e => e.stopPropagation()}
          >
            <button
              onClick={handleModalClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>

            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              {task.name} Template
            </h3>

            {error && (
              <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/50 text-red-600 dark:text-red-200 rounded-lg">
                {error}
              </div>
            )}

            <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4 mb-4 whitespace-pre-wrap">
              {fillTemplate()}
            </div>

            <button
              onClick={copyToClipboard}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {copied ? (
                <>
                  <CheckCircle className="h-4 w-4 mr-2" />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="h-4 w-4 mr-2" />
                  Copy to Clipboard
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskActionButton;