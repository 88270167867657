import React, { useState, useEffect } from 'react';
import { Plus, Copy, Save, Trash2, AlertCircle, CheckCircle, Code } from 'lucide-react';
import { supabase } from '../../config/supabaseConfig';

// Available variable definitions based on database schema
const AVAILABLE_VARIABLES = {
  contact: {
    label: 'Contact Variables',
    variables: {
      '[contact.first_name]': 'First Name',
      '[contact.last_name]': 'Last Name',
      '[contact.full_name]': 'Full Name',
      '[contact.title]': 'Job Title',
      '[contact.email]': 'Email Address',
      '[contact.company]': 'Company Name',
      '[contact.industry]': 'Industry',
      '[contact.city]': 'City',
      '[contact.state]': 'State',
      '[contact.country]': 'Country'
    }
  },
  team_member: {
    label: 'Team Member Variables',
    variables: {
      '[team_member.first_name]': 'First Name',
      '[team_member.last_name]': 'Last Name',
      '[team_member.full_name]': 'Full Name',
      '[team_member.email]': 'Email Address',
      '[team_member.title]': 'Job Title'
    }
  },
  company: {
    label: 'Company Variables',
    variables: {
      '[company.name]': 'Company Name',
      '[company.address]': 'Company Address',
      '[company.city]': 'Company City',
      '[company.state]': 'Company State',
      '[company.country]': 'Company Country',
      '[company.phone]': 'Company Phone'
    }
  }
};

const TEMPLATE_TYPES = {
  EMAIL: {
    name: 'Email',
    format: 'HTML',
    icon: 'mail'
  },
  LINKEDIN_CONNECTION: {
    name: 'LinkedIn Connection',
    format: 'TEXT',
    icon: 'linkedin'
  },
  LINKEDIN_MESSAGE: {
    name: 'LinkedIn Message',
    format: 'TEXT',
    icon: 'linkedin'
  },
  PHONE_SCRIPT: {
    name: 'Phone Script',
    format: 'TEXT',
    icon: 'phone'
  },
  PLAINTEXT: {
    name: 'Plain Text',
    format: 'TEXT',
    icon: 'file-text'
  }
};

const TemplateManager = () => {
  const [templates, setTemplates] = useState([]);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState('EMAIL');
  const [previewData, setPreviewData] = useState(null);
  const [showVariableHelper, setShowVariableHelper] = useState(false);

  useEffect(() => {
    fetchTemplates();
    fetchPreviewData();
  }, []);

  const fetchPreviewData = async () => {
    try {
      // Fetch a sample contact with team member data for preview
      const { data, error } = await supabase
        .from('contacts')
        .select(`
          *,
          team_members!contacts_assigned_to_fkey (*)
        `)
        .limit(1)
        .single();

      if (error) throw error;
      setPreviewData(data);
    } catch (err) {
      console.error('Error fetching preview data:', err);
    }
  };

  const fetchTemplates = async () => {
    try {
      const { data, error } = await supabase
        .from('templates')
        .select('*')
        .order('name');
      
      if (error) throw error;
      setTemplates(data || []);
    } catch (err) {
      console.error('Error fetching templates:', err);
      setError('Failed to load templates');
    }
  };

  const handleSave = async () => {
    try {
      if (!editingTemplate.name || !editingTemplate.content) {
        throw new Error('Name and content are required');
      }
  
      const templateData = {
        name: editingTemplate.name.trim(),
        content: editingTemplate.content.trim(), // Always save content
        html_template: editingTemplate.format === 'HTML' ? editingTemplate.content.trim() : null,
        type: editingTemplate.type,
        format: editingTemplate.format,
        variables: extractVariables(editingTemplate.content)
      };
  
      let result;
      if (editingTemplate.id) {
        result = await supabase
          .from('templates')
          .update(templateData)
          .eq('id', editingTemplate.id)
          .select();
      } else {
        result = await supabase
          .from('templates')
          .insert([templateData])
          .select();
      }
  
      if (result.error) throw result.error;
  
      await fetchTemplates();
      setEditingTemplate(null);
    } catch (err) {
      console.error('Error saving template:', err);
      setError(err.message);
    }
  };

  // Extract variables from template content
  const extractVariables = (content) => {
    const matches = content.match(/\[([^\]]+)\]/g) || [];
    return [...new Set(matches)];
  };

  // Insert variable at cursor position
  const insertVariable = (variable) => {
    if (!editingTemplate) return;

    const textarea = document.getElementById('template-content');
    const cursorPos = textarea.selectionStart;
    const textBefore = editingTemplate.content.substring(0, cursorPos);
    const textAfter = editingTemplate.content.substring(textarea.selectionEnd);

    setEditingTemplate({
      ...editingTemplate,
      content: `${textBefore}${variable}${textAfter}`
    });

    // Reset cursor position after variable insertion
    setTimeout(() => {
      textarea.focus();
      const newPos = cursorPos + variable.length;
      textarea.setSelectionRange(newPos, newPos);
    }, 0);
  };

  const renderVariableHelper = () => (
    <div className="border dark:border-gray-700 rounded-lg p-4 bg-gray-50 dark:bg-gray-800">
      <h4 className="text-lg font-medium mb-4">Available Variables</h4>
      <div className="space-y-4">
        {Object.entries(AVAILABLE_VARIABLES).map(([section, { label, variables }]) => (
          <div key={section}>
            <h5 className="font-medium mb-2">{label}</h5>
            <div className="grid grid-cols-2 gap-2">
              {Object.entries(variables).map(([variable, description]) => (
                <button
                  key={variable}
                  onClick={() => insertVariable(variable)}
                  className="text-left px-2 py-1 text-sm rounded hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <div className="font-mono text-blue-600 dark:text-blue-400">{variable}</div>
                  <div className="text-gray-600 dark:text-gray-400 text-xs">{description}</div>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Message Templates
        </h2>
        <button
          onClick={() => setEditingTemplate({ 
            type: selectedType,
            format: 'TEXT',
            content: ''
          })}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="h-5 w-5 mr-2" />
          New Template
        </button>
      </div>

      {/* Type Filter */}
      <div className="flex space-x-2">
        {Object.entries(TEMPLATE_TYPES).map(([type, info]) => (
          <button
            key={type}
            onClick={() => setSelectedType(type)}
            className={`px-4 py-2 rounded-lg ${
              selectedType === type
                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-200'
                : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
            }`}
          >
            {info.name}
          </button>
        ))}
      </div>

      {/* Error Display */}
      {error && (
        <div className="p-4 bg-red-50 text-red-800 dark:bg-red-900/50 dark:text-red-200 rounded-lg flex items-start gap-2">
          <AlertCircle className="h-5 w-5 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      {/* Template List */}
      <div className="grid gap-4">
        {templates
          .filter(template => template.type === selectedType)
          .map(template => (
            <div
              key={template.id}
              className="bg-white dark:bg-gray-800 rounded-lg shadow p-4"
            >
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    {template.name}
                  </h3>
                  <div className="flex items-center gap-2">
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {TEMPLATE_TYPES[template.type]?.name}
                    </p>
                    <span className="text-xs px-2 py-0.5 bg-gray-100 dark:bg-gray-700 rounded">
                      {template.format}
                    </span>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      const content = template.format === 'HTML' ? 
                        template.html_template : 
                        template.content;
                      navigator.clipboard.writeText(content);
                    }}
                    className="p-2 text-gray-400 hover:text-gray-500"
                    title="Copy template"
                  >
                    <Copy className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => setEditingTemplate({
                      ...template,
                      content: template.format === 'HTML' ? template.html_template : template.content
                    })}
                    className="p-2 text-blue-600 hover:text-blue-700"
                    title="Edit template"
                  >
                    <Save className="h-5 w-5" />
                  </button>
                </div>
              </div>

              {/* Template Content Preview */}
              <div className="bg-gray-50 dark:bg-gray-700 rounded p-4">
                {template.format === 'HTML' ? (
                  <div className="prose dark:prose-invert max-w-none">
                    <div dangerouslySetInnerHTML={{ 
                      __html: template.html_template  // Changed from template.content
                    }} />
                  </div>
                ) : (
                  <pre className="whitespace-pre-wrap font-mono text-sm">
                    {template.content}
                  </pre>
                )}
              </div>

              {/* Variables Used */}
              {template.variables?.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                    Variables Used:
                  </h4>
                  <div className="flex flex-wrap gap-2">
                    {template.variables.map(variable => (
                      <span
                        key={variable}
                        className="px-2 py-1 bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-200 rounded text-sm font-mono"
                      >
                        {variable}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>

      {/* Edit Modal */}
      {editingTemplate && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="p-4 border-b dark:border-gray-700">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {editingTemplate.id ? 'Edit Template' : 'New Template'}
              </h3>
            </div>

            <div className="p-4 grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Template Name
                  </label>
                  <input
                    type="text"
                    value={editingTemplate.name || ''}
                    onChange={(e) => setEditingTemplate({
                      ...editingTemplate,
                      name: e.target.value
                    })}
                    className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                    placeholder="Enter template name"
                  />
                </div>

                <div className="flex gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Template Type
                    </label>
                    <select
                      value={editingTemplate.type || 'EMAIL'}
                      onChange={(e) => setEditingTemplate({
                        ...editingTemplate,
                        type: e.target.value,
                        format: TEMPLATE_TYPES[e.target.value].format // Automatically set format based on type
                      })}
                      className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
                    >
                      {Object.entries(TEMPLATE_TYPES).map(([type, info]) => (
                        <option key={type} value={type}>{info.name}</option>
                      ))}
                    </select>
                  </div>

                </div>

                <div className="relative">
                  <label className="block text-sm font-medium mb-1">
                    Template Content
                  </label>
                  <div className="relative">
                    <button
                      onClick={() => setShowVariableHelper(!showVariableHelper)}
                      className="absolute right-2 top-2 p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                      title="Toggle variable helper"
                    >
                      <Code className="h-5 w-5" />
                    </button>
                    <textarea
                      id="template-content"
                      value={editingTemplate.content || ''}
                      onChange={(e) => setEditingTemplate({
                        ...editingTemplate,
                        content: e.target.value
                      })}
                      className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600 h-[400px] font-mono"
                      placeholder={`Enter template content. Use variables like [contact.first_name]${
                        editingTemplate.format === 'HTML' ? '\nHTML formatting is supported' : ''
                      }`}
                    />
                  </div>
                </div>

                {showVariableHelper && (
                  <div className="mt-4">
                    {renderVariableHelper()}
                  </div>
                )}
              </div>

              {/* Preview Section */}
              <div className="space-y-4">
                <h4 className="text-lg font-medium">Preview</h4>
                <div className="border dark:border-gray-700 rounded-lg p-4 bg-gray-50 dark:bg-gray-800 h-[500px] overflow-auto">
                  {editingTemplate.format === 'HTML' ? (
                    <div className="prose dark:prose-invert max-w-none">
                      <div dangerouslySetInnerHTML={{ 
                        __html: editingTemplate.content 
                      }} />
                    </div>
                  ) : (
                    <pre className="whitespace-pre-wrap font-mono text-sm">
                      {editingTemplate.content}
                    </pre>
                  )}
                </div>
              </div>
            </div>

            <div className="p-4 border-t dark:border-gray-700 flex justify-between">
              <button
                onClick={() => setEditingTemplate(null)}
                className="px-4 py-2 border rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Cancel
              </button>
              <div className="flex gap-2">
                {editingTemplate.id && (
                  <button
                    onClick={async () => {
                      if (window.confirm('Are you sure you want to delete this template?')) {
                        try {
                          const { error } = await supabase
                            .from('templates')
                            .delete()
                            .eq('id', editingTemplate.id);

                          if (error) throw error;
                          await fetchTemplates();
                          setEditingTemplate(null);
                        } catch (err) {
                          console.error('Error deleting template:', err);
                          setError('Failed to delete template');
                        }
                      }
                    }}
                    className="px-4 py-2 text-red-600 hover:text-red-700 font-medium"
                  >
                    Delete
                  </button>
                )}
                <button
                  onClick={handleSave}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Save Template
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateManager;