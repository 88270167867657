import React, { useState, useEffect } from 'react';
import { 
  Mail, 
  Linkedin, 
  Phone, 
  CheckCircle, 
  Clock, 
  XCircle, 
  AlertCircle,
  Calendar 
} from 'lucide-react';
import { supabase } from '../../config/supabaseConfig';
import { TASK_STATUS, TASK_TYPES } from '../../config/constants';
import TaskActionButton from './TaskActionButton';

const TaskTimeline = ({ tasks = [], contact = null, onTaskClick, onStatusChange }) => {
  const [marketingPlanSteps, setMarketingPlanSteps] = useState([]);

  useEffect(() => {
    fetchMarketingPlan();
  }, []);

  const fetchMarketingPlan = async () => {
    try {
      const { data, error } = await supabase
        .from('marketing_plan_tasks')
        .select('*')
        .eq('is_active', true)
        .order('sort_order');
      
      if (error) throw error;
      setMarketingPlanSteps(data || []);
    } catch (err) {
      console.error('Error fetching marketing plan:', err);
    }
  };

  // Helper function to get task icon
  const getTaskIcon = (type) => {
    switch (type) {
      case 'EMAIL':
        return <Mail className="h-5 w-5" />;
      case 'LINKEDIN':
        return <Linkedin className="h-5 w-5" />;
      case 'CALL':
        return <Phone className="h-5 w-5" />;
      default:
        return null;
    }
  };

  // Helper function to get status icon and color
  const getStatusInfo = (status) => {
    switch (status) {
      case TASK_STATUS.COMPLETED:
        return { 
          icon: <CheckCircle className="h-5 w-5" />, 
          color: 'text-green-500', 
          bgColor: 'bg-green-50 dark:bg-green-900/20',
          borderColor: 'border-green-500'
        };
      case TASK_STATUS.IN_PROGRESS:
        return { 
          icon: <Clock className="h-5 w-5" />, 
          color: 'text-blue-500', 
          bgColor: 'bg-blue-50 dark:bg-blue-900/20',
          borderColor: 'border-blue-500'
        };
      case TASK_STATUS.FAILED:
        return { 
          icon: <XCircle className="h-5 w-5" />, 
          color: 'text-red-500', 
          bgColor: 'bg-red-50 dark:bg-red-900/20',
          borderColor: 'border-red-500'
        };
      case TASK_STATUS.SKIPPED:
        return { 
          icon: <AlertCircle className="h-5 w-5" />, 
          color: 'text-yellow-500', 
          bgColor: 'bg-yellow-50 dark:bg-yellow-900/20',
          borderColor: 'border-yellow-500'
        };
      default:
        return { 
          icon: <Clock className="h-5 w-5" />, 
          color: 'text-gray-400', 
          bgColor: 'bg-gray-50 dark:bg-gray-800',
          borderColor: 'border-gray-300 dark:border-gray-600'
        };
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  // Check if a date is in the past
  const isOverdue = (dateString, status) => {
    if (status === TASK_STATUS.COMPLETED || status === TASK_STATUS.SKIPPED) {
      return false;
    }
    try {
      const taskDate = new Date(dateString);
      return taskDate < new Date();
    } catch (error) {
      return false;
    }
  };

  // Find task for a specific day
  const findTask = (day) => {
    return tasks.find(task => task.day_number === day);
  };

  return (
    <div className="w-full p-6">
      <div className="relative">
        {/* Timeline Line */}
        <div className="absolute left-[4.5rem] top-0 bottom-0 w-0.5 bg-gray-200 dark:bg-gray-700"></div>

        {/* Timeline Steps */}
        <div className="space-y-8">
          {marketingPlanSteps.map((step) => {
            const task = findTask(step.day_number);
            const statusInfo = task ? getStatusInfo(task.status) : getStatusInfo('PENDING');
            const isTaskOverdue = task ? isOverdue(task.scheduled_date, task.status) : false;

            return (
              <div key={step.day_number} className="relative flex items-start group">
                {/* Day Number */}
                <div className="flex-shrink-0 w-16 text-sm font-medium text-gray-500 dark:text-gray-300 pt-3">
                  Day {step.day_number}
                </div>

                {/* Timeline Node */}
                <div 
                  className={`
                    flex-shrink-0 w-12 h-12 rounded-full border-2
                    flex items-center justify-center 
                    transition-colors duration-200
                    ${statusInfo.borderColor}
                    ${statusInfo.bgColor}
                    ${isTaskOverdue ? 'animate-pulse' : ''}
                  `}
                >
                  {getTaskIcon(step.task_type)}
                </div>

                {/* Task Content */}
                <div className="ml-4 flex-grow">
                  <div 
                    className={`
                      p-4 rounded-lg cursor-pointer 
                      ${statusInfo.bgColor}
                      hover:brightness-95 dark:hover:brightness-110
                      transition-all duration-200
                    `}
                    onClick={() => task ? onTaskClick(task) : onTaskClick({ 
                      day_number: step.day_number,
                      task_type: step.task_type,
                      name: step.name,
                      status: TASK_STATUS.PENDING
                    })}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2 flex-grow">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                          {step.name}
                        </h3>
                        {task && (
                          <div className={`${statusInfo.color}`}>
                            {statusInfo.icon}
                          </div>
                        )}
                        {isTaskOverdue && (
                          <span className="text-xs px-2 py-1 bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-200 rounded-full">
                            Overdue
                          </span>
                        )}
                      </div>

                      {/* Task Action Button */}
                      {task && (
                        <TaskActionButton task={task} />
                      )}
                    </div>
                    
                    <p className="text-gray-600 dark:text-gray-300 mb-2">
                      {step.description}
                    </p>
                    
                    {task && (
                      <div className="mt-2 space-y-1 text-sm">
                        <div className="flex items-center text-gray-500 dark:text-gray-400">
                          <Calendar className="h-4 w-4 mr-1" />
                          Scheduled: {formatDate(task.scheduled_date)}
                        </div>
                        {task.completed_date && (
                          <div className="flex items-center text-gray-500 dark:text-gray-400">
                            <CheckCircle className="h-4 w-4 mr-1" />
                            Completed: {formatDate(task.completed_date)}
                          </div>
                        )}
                        {task.template_name && (
                          <div className="text-gray-500 dark:text-gray-400">
                            Template: {task.template_name}
                          </div>
                        )}
                        {task.notes && (
                          <p className="text-gray-600 dark:text-gray-300 italic">
                            "{task.notes}"
                          </p>
                        )}
                      </div>
                    )}
                    
                    {/* Status Selection for Existing Tasks */}
                    {task && (
                      <div className="mt-4">
                        <select
                          value={task.status}
                          onChange={(e) => onStatusChange(task.id, e.target.value)}
                          className={`
                            text-sm border rounded-md px-2 py-1 
                            bg-white dark:bg-gray-700 dark:border-gray-600
                            transition-colors duration-200
                          `}
                        >
                          {Object.values(TASK_STATUS).map(status => (
                            <option key={status} value={status}>{status}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TaskTimeline;