import React from 'react';
import { Filter, ChevronLeft } from 'lucide-react';
import { TASK_STATUS } from '../../config/constants';
import { useNavigate } from 'react-router-dom';

const TaskHeader = ({ 
  statusFilter, 
  onStatusFilterChange,
  tasksStats = null,
  contactName = null
}) => {
  const navigate = useNavigate();

  const getStatusCount = (status) => {
    if (!tasksStats) return null;
    return tasksStats[status] || 0;
  };

  return (
    <div className="space-y-4">
      {/* Navigation and Title */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate(-1)}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            aria-label="Go back"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Tasks
            </h2>
            {contactName && (
              <p className="text-sm text-gray-500 dark:text-gray-400">
                for {contactName}
              </p>
            )}
          </div>
        </div>

        {/* Status Filter */}
        <div className="flex items-center space-x-2">
          <Filter className="h-5 w-5 text-gray-400" />
          <select
            value={statusFilter}
            onChange={(e) => onStatusFilterChange(e.target.value)}
            className="border rounded-md px-3 py-1.5 text-sm 
              dark:bg-gray-700 dark:border-gray-600 
              text-gray-900 dark:text-white
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500
              transition-all duration-200"
          >
            <option value="ALL">All Status</option>
            {Object.values(TASK_STATUS).map(status => (
              <option key={status} value={status}>
                {status} {getStatusCount(status) !== null && `(${getStatusCount(status)})`}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Task Statistics */}
      {tasksStats && (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {Object.values(TASK_STATUS).map(status => {
            const count = getStatusCount(status);
            let bgColor, textColor;
            
            switch (status) {
              case TASK_STATUS.COMPLETED:
                bgColor = 'bg-green-50 dark:bg-green-900/20';
                textColor = 'text-green-800 dark:text-green-200';
                break;
              case TASK_STATUS.IN_PROGRESS:
                bgColor = 'bg-blue-50 dark:bg-blue-900/20';
                textColor = 'text-blue-800 dark:text-blue-200';
                break;
              case TASK_STATUS.FAILED:
                bgColor = 'bg-red-50 dark:bg-red-900/20';
                textColor = 'text-red-800 dark:text-red-200';
                break;
              case TASK_STATUS.SKIPPED:
                bgColor = 'bg-yellow-50 dark:bg-yellow-900/20';
                textColor = 'text-yellow-800 dark:text-yellow-200';
                break;
              default:
                bgColor = 'bg-gray-50 dark:bg-gray-800';
                textColor = 'text-gray-800 dark:text-gray-200';
            }

            return (
              <div
                key={status}
                className={`${bgColor} rounded-lg p-3 transition-all duration-200
                  ${statusFilter === status ? 'ring-2 ring-blue-500' : ''}
                  cursor-pointer hover:brightness-95 dark:hover:brightness-110`}
                onClick={() => onStatusFilterChange(status)}
              >
                <div className={`text-sm font-medium ${textColor}`}>
                  {status}
                </div>
                <div className="text-2xl font-bold mt-1">
                  {count}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TaskHeader;