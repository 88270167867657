import { createClient } from '@supabase/supabase-js';
import { ERROR_MESSAGES } from './constants';

/**
 * Create and configure Supabase client
 */
export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL || 'https://hnnmuhrcsegmehjxesnt.supabase.co',
  process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imhubm11aHJjc2VnbWVoanhlc250Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMzNDMzMTcsImV4cCI6MjA0ODkxOTMxN30.sO8EHDHzD58oG6sevTDKkUDr7OSjVHQjbPwCh6adouk'
);

/**
 * Helper function to handle Supabase errors
 * @param {Error} error - The error object from Supabase
 * @returns {string} Appropriate error message for the user
 */
export const handleSupabaseError = (error) => {
  if (!error) return ERROR_MESSAGES.SERVER_ERROR;

  switch (error.status) {
    case 404:
      return ERROR_MESSAGES.NOT_FOUND;
    case 422:
      return ERROR_MESSAGES.VALIDATION_ERROR;
    default:
      return ERROR_MESSAGES.SERVER_ERROR;
  }
};

export default supabase;
