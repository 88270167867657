import React, { useState, useEffect } from 'react';
import { AlertCircle, Plus, X, Save, ArrowRight } from 'lucide-react';
import { supabase } from '../../config/supabaseConfig';
import { TASK_TYPES } from '../../config/constants';

const TemplateTaskManager = () => {
  const [tasks, setTasks] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [editingMapping, setEditingMapping] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState('ALL');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      fetchTasks(),
      fetchTemplates(),
      fetchMappings()
    ]).finally(() => setLoading(false));
  }, []);

  const fetchTasks = async () => {
    try {
      const { data, error } = await supabase
        .from('marketing_plan_tasks')
        .select('*')
        .order('sort_order');
      
      if (error) throw error;
      setTasks(data || []);
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setError('Failed to load tasks');
    }
  };

  const fetchTemplates = async () => {
    try {
      const { data, error } = await supabase
        .from('templates')
        .select('*')
        .order('name');
      
      if (error) throw error;
      setTemplates(data || []);
    } catch (err) {
      console.error('Error fetching templates:', err);
      setError('Failed to load templates');
    }
  };

  const fetchMappings = async () => {
    try {
      const { data, error } = await supabase
        .from('template_task_mappings')
        .select(`
          *,
          marketing_plan_tasks (
            id,
            name,
            task_type,
            day_number
          ),
          templates (
            id,
            name,
            type
          )
        `);
      
      if (error) throw error;
      setMappings(data || []);
    } catch (err) {
      console.error('Error fetching mappings:', err);
      setError('Failed to load template mappings');
    }
  };

  const handleSaveMapping = async () => {
    try {
      if (!editingMapping.task_id || !editingMapping.template_id) {
        throw new Error('Task and template are required');
      }
  
      // Check if there's any existing mapping for this task (even if different template)
      const { data: existingMapping, error: checkError } = await supabase
        .from('template_task_mappings')
        .select('*')
        .eq('task_id', editingMapping.task_id);
  
      if (checkError) throw checkError;
  
      let result;
      
      if (existingMapping?.length > 0) {
        // If we're editing the current mapping, update it
        if (editingMapping.id === existingMapping[0].id) {
          result = await supabase
            .from('template_task_mappings')
            .update({
              template_id: editingMapping.template_id
            })
            .eq('id', editingMapping.id)
            .select();
        } else {
          // If trying to create a new mapping for a task that already has one
          throw new Error('This task already has a template assigned. Please edit the existing mapping instead.');
        }
      } else {
        // Create new mapping if none exists for this task
        result = await supabase
          .from('template_task_mappings')
          .insert([{
            task_id: editingMapping.task_id,
            template_id: editingMapping.template_id
          }])
          .select();
      }
  
      if (result.error) throw result.error;
      
      await fetchMappings();
      setEditingMapping(null);
    } catch (err) {
      console.error('Error saving mapping:', err);
      setError(err.message);
    }
  };

  const handleDeleteMapping = async (mappingId) => {
    if (!window.confirm('Are you sure you want to delete this mapping?')) return;

    try {
      const { error } = await supabase
        .from('template_task_mappings')
        .delete()
        .eq('id', mappingId);

      if (error) throw error;
      await fetchMappings();
    } catch (err) {
      console.error('Error deleting mapping:', err);
      setError('Failed to delete mapping');
    }
  };

  const filteredTasks = selectedTaskType === 'ALL' 
    ? tasks 
    : tasks.filter(task => task.task_type === selectedTaskType);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Template Task Mappings
        </h2>
        <button
            onClick={() => setEditingMapping({ 
                is_primary: false,
                task_id: '',
                template_id: ''
            })}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
            <Plus className="h-5 w-5 mr-2" />
            Add Mapping
        </button>
      </div>

      {/* Task Type Filter */}
      <div className="flex space-x-2">
        <button
          onClick={() => setSelectedTaskType('ALL')}
          className={`px-4 py-2 rounded-lg ${
            selectedTaskType === 'ALL'
              ? 'bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-200'
              : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
          }`}
        >
          All Types
        </button>
        {Object.entries(TASK_TYPES).map(([type, info]) => (
          <button
            key={type}
            onClick={() => setSelectedTaskType(type)}
            className={`px-4 py-2 rounded-lg ${
              selectedTaskType === type
                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-200'
                : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
            }`}
          >
            {info.name}
          </button>
        ))}
      </div>

      {/* Error Display */}
      {error && (
        <div className="p-4 bg-red-50 text-red-800 dark:bg-red-900/50 dark:text-red-200 rounded-lg flex items-start gap-2">
          <AlertCircle className="h-5 w-5 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      {/* Task Grid */}
      <div className="grid gap-4">
        {filteredTasks.map(task => {
          const taskMappings = mappings.filter(m => m.task_id === task.id);
          
          return (
            <div
              key={task.id}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4"
            >
              {/* Task Info */}
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    Day {task.day_number}: {task.name}
                  </h3>
                  <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="px-2 py-0.5 bg-gray-100 dark:bg-gray-700 rounded">
                      {TASK_TYPES[task.task_type]?.name}
                    </span>
                    {task.description && (
                      <span className="truncate">{task.description}</span>
                    )}
                  </div>
                </div>
              </div>

              {/* Template Mappings */}
              <div className="space-y-2">
                {taskMappings.map(mapping => (
                  <div 
                    key={mapping.id}
                    className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-700 rounded-lg"
                  >
                    <div className="flex items-center gap-2">
                      <ArrowRight className="h-4 w-4 text-gray-400" />
                      <span className="text-gray-900 dark:text-white">
                        {mapping.templates.name}
                      </span>
                      {mapping.is_primary && (
                        <span className="px-2 py-0.5 bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-200 rounded text-xs">
                          Primary
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => setEditingMapping({
                          id: mapping.id,
                          task_id: mapping.task_id,
                          template_id: mapping.template_id,
                          is_primary: mapping.is_primary
                        })}
                        className="p-1 text-gray-400 hover:text-gray-500"
                      >
                        <Save className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteMapping(mapping.id)}
                        className="p-1 text-red-400 hover:text-red-500"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                ))}

                {taskMappings.length === 0 && (
                  <p className="text-sm text-gray-500 dark:text-gray-400 italic">
                    No templates mapped to this task
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Edit Modal */}
      {/* Edit Modal */}
{editingMapping && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
    <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-lg">
      {/* Modal Header */}
      <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          {editingMapping.id ? 'Edit Mapping' : 'New Mapping'}
        </h3>
        <button
          onClick={() => setEditingMapping(null)}
          className="text-gray-400 hover:text-gray-500"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      {/* Modal Content */}
      <div className="p-4 space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">
            Task*
          </label>
          <select
            value={editingMapping.task_id || ''}
            onChange={(e) => setEditingMapping({
              ...editingMapping,
              task_id: e.target.value
            })}
            className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
          >
            <option value="">Select task</option>
            {tasks.map(task => (
              <option key={task.id} value={task.id}>
                Day {task.day_number}: {task.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">
            Template*
          </label>
          <select
            value={editingMapping.template_id || ''}
            onChange={(e) => setEditingMapping({
              ...editingMapping,
              template_id: e.target.value
            })}
            className="w-full border rounded-lg px-3 py-2 dark:bg-gray-700 dark:border-gray-600"
          >
            <option value="">Select template</option>
            {templates.map(template => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Modal Footer */}
      <div className="flex justify-end p-4 border-t dark:border-gray-700">
        <div className="flex space-x-2">
          <button
            onClick={() => setEditingMapping(null)}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
            type="button"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveMapping}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            type="button"
          >
            Save Mapping
          </button>
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default TemplateTaskManager;