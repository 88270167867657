import React from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import { ListTodo, Mail } from 'lucide-react';

const MarketingLayout = () => {
  const location = useLocation();

  const navigation = [
    {
      name: 'Task Definitions',
      href: '/marketing/tasks',
      icon: ListTodo
    },
    {
      name: 'Template Mappings',
      href: '/marketing/templates',
      icon: Mail
    }
  ];

  return (
    <div className="space-y-6">
      {/* Navigation Tabs */}
      <nav className="flex space-x-4 border-b dark:border-gray-700 pb-px">
        {navigation.map((item) => {
          const isActive = location.pathname === item.href;
          return (
            <Link
              key={item.name}
              to={item.href}
              className={`
                flex items-center px-4 py-2 font-medium text-sm rounded-t-lg -mb-px
                ${isActive
                  ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                  : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                }
              `}
            >
              <item.icon className="h-5 w-5 mr-2" />
              {item.name}
            </Link>
          );
        })}
      </nav>

      {/* Content Area */}
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default MarketingLayout;