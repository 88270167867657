/**
 * Timeline steps for the 45-day outreach process
 * Each step includes the day number, task type, and description
 */
export const TIMELINE_STEPS = [
    {
      day: 1,
      type: 'EMAIL',
      name: 'Initial Email',
      description: 'First contact email introduction'
    },
    {
      day: 5,
      type: 'LINKEDIN',
      name: 'LinkedIn Connection',
      description: 'Send LinkedIn connection request'
    },
    {
      day: 10,
      type: 'EMAIL',
      name: 'Follow-up Email',
      description: 'Second email following up on initial contact'
    },
    {
      day: 15,
      type: 'LINKEDIN',
      name: 'LinkedIn Engagement',
      description: 'Engage with prospect\'s LinkedIn content'
    },
    {
      day: 20,
      type: 'LINKEDIN',
      name: 'InMail/Direct Message',
      description: 'Send LinkedIn InMail or direct message'
    },
    {
      day: 25,
      type: 'LINKEDIN',
      name: 'Content Share',
      description: 'Share relevant content with prospect'
    },
    {
      day: 30,
      type: 'CALL',
      name: 'Phone Call',
      description: 'Attempt direct phone contact'
    },
    {
      day: 35,
      type: 'EMAIL',
      name: 'Third Email',
      description: 'Final email follow-up'
    },
    {
      day: 40,
      type: 'LINKEDIN',
      name: 'LinkedIn Engagement',
      description: 'Final LinkedIn content engagement'
    },
    {
      day: 45,
      type: 'EMAIL',
      name: 'Final Email',
      description: 'Closing email in sequence'
    }
  ];
  
  /**
   * Task types and their properties
   */
  export const TASK_TYPES = {
    EMAIL: {
      name: 'Email',
      icon: 'mail',
      requiresTemplate: true
    },
    LINKEDIN: {
      name: 'LinkedIn',
      icon: 'linkedin',
      requiresTemplate: true
    },
    CALL: {
      name: 'Phone Call',
      icon: 'phone',
      requiresTemplate: false
    }
  };
  
  /**
   * Contact status types
   */
  export const CONTACT_STATUS = {
    NEW: 'New',
    IN_PROGRESS: 'In Progress',
    RESPONDED: 'Responded',
    MEETING_SCHEDULED: 'Meeting Scheduled',
    CLOSED_WON: 'Closed Won',
    CLOSED_LOST: 'Closed Lost',
    ON_HOLD: 'On Hold'
  };
  
  /**
   * Task completion status types
   */
  export const TASK_STATUS = {
    PENDING: 'Pending',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    SKIPPED: 'Skipped',
    FAILED: 'Failed'
  };
  
  /**
   * Theme configuration
   */
  export const THEME = {
    LIGHT: 'light',
    DARK: 'dark'
  };
  
  /**
   * CSV import field mappings
   * Maps CSV column headers to internal data structure
   */
  export const CSV_FIELD_MAPPINGS = {
    'First Name': 'firstName',
    'Last Name': 'lastName',
    'Title': 'title',
    'Company': 'company',
    'Email': 'email',
    'LinkedIn URL': 'linkedinUrl',
    'Phone': 'phone',
    'Industry': 'industry'
  };
  
  /**
   * Application-wide validation rules
   */
  export const VALIDATION_RULES = {
    EMAIL_REGEX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    PHONE_REGEX: /^\+?[\d\s-()]{10,}$/,
    LINKEDIN_URL_REGEX: /^https:\/\/www\.linkedin\.com\/.*$/,
    MAX_NOTE_LENGTH: 1000
  };
  
  /**
   * API error messages
   */
  export const ERROR_MESSAGES = {
    UNAUTHORIZED: 'Please sign in to continue',
    FORBIDDEN: 'You don\'t have permission to perform this action',
    NOT_FOUND: 'The requested resource was not found',
    VALIDATION_ERROR: 'Please check your input and try again',
    SERVER_ERROR: 'An unexpected error occurred. Please try again later'
  };

  // Add this to your existing constants.js file

  export const TEMPLATE_TYPES = {
    EMAIL: {
      name: 'Email',
      format: 'HTML',
      icon: 'mail'
    },
    LINKEDIN_CONNECTION: {
      name: 'LinkedIn Connection',
      format: 'TEXT',
      icon: 'linkedin'
    },
    LINKEDIN_MESSAGE: {
      name: 'LinkedIn Message',
      format: 'TEXT',
      icon: 'linkedin'
    },
    PHONE_SCRIPT: {
      name: 'Phone Script',
      format: 'TEXT',
      icon: 'phone'
    },
    PLAINTEXT: {
      name: 'Plain Text',
      format: 'TEXT',
      icon: 'file-text'
    }
  };

export const TEMPLATE_TYPE_NAMES = {
  EMAIL: 'Email',
  LINKEDIN_CONNECTION: 'LinkedIn Connection Request',
  LINKEDIN_MESSAGE: 'LinkedIn Message'
};

export const DEFAULT_VARIABLES = [
  'First Name',
  'Contact\'s Name',
  'Company Name',
  'Position',
  'Your Name',
  'Your Contact Information'
];

export const TEMPLATE_FORMATS = {
  TEXT: 'TEXT',
  HTML: 'HTML'
};