import React, { useState, useEffect } from 'react';
import { Search, ChevronDown, ChevronUp, Mail, Phone, Linkedin, ListTodo } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../config/supabaseConfig';
import { CONTACT_STATUS, TASK_TYPES } from '../../config/constants';
import { createTasksForContact } from '../../utils/taskUtils';

const ContactList = () => {
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({
    key: 'last_name',
    direction: 'asc'
  });
  const [selectedStatus, setSelectedStatus] = useState('ALL');
  const [updatingContact, setUpdatingContact] = useState(null);

  useEffect(() => {
    fetchContacts();
    fetchTeamMembers();
  }, []);

  // In the fetchTeamMembers function
  const fetchTeamMembers = async () => {
  try {
    console.log('Fetching team members...');
    const { data, error } = await supabase
      .from('team_members')
      .select('id, first_name, last_name')
      .order('first_name');
        
    if (error) throw error;
    console.log('Team members fetched:', data); // See what data we're getting
    setTeamMembers(data || []);
  } catch (err) {
    console.error('Error fetching team members:', err);
  }
};

  const fetchContacts = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from('contacts')
        .select(`
          *,
          tasks (
            id,
            task_type,
            status,
            scheduled_date,
            completed_date,
            name
          ),
          team_members!contacts_assigned_to_fkey (
            id,
            first_name,
            last_name
          )
        `);

      if (error) throw error;

      setContacts(data || []);
    } catch (err) {
      console.error('Error fetching contacts:', err);
      setError('Failed to load contacts. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAssignTeamMember = async (contactId, teamMemberId) => {
    let previousAssignment;
    try {
      if (updatingContact) return; // Prevent multiple simultaneous updates
      setUpdatingContact(contactId);
      setError(null);
  
      // Find the current contact and store its current assignment
      const currentContact = contacts.find(c => c.id === contactId);
      if (!currentContact) {
        throw new Error('Contact not found');
      }
      previousAssignment = currentContact.assigned_to;  // Store the current assignment before update
  
      // Optimistically update UI
      setContacts(currentContacts =>
        currentContacts.map(c =>
          c.id === contactId
            ? { ...c, assigned_to: teamMemberId }
            : c
        )
      );
  
      const { error: updateError } = await supabase
        .from('contacts')
        .update({ assigned_to: teamMemberId })
        .eq('id', contactId);
  
      if (updateError) throw updateError;
  
      // Refresh contacts to get updated data
      await fetchContacts();
    } catch (err) {
      console.error('Error assigning team member:', err);
      setError('Failed to assign team member');
      
      // Revert optimistic update on error
      setContacts(currentContacts =>
        currentContacts.map(c =>
          c.id === contactId
            ? { ...c, assigned_to: previousAssignment }
            : c
        )
      );
    } finally {
      setUpdatingContact(null);
    }
  };
  
  const handleStatusChange = async (contactId, newStatus) => {
    let previousStatus;
    try {
      if (updatingContact) return; // Prevent multiple simultaneous updates
      setUpdatingContact(contactId);
      setError(null);
  
      // Find the contact and store the current status
      const currentContact = contacts.find(c => c.id === contactId);
      if (!currentContact) {
        throw new Error('Contact not found');
      }
      previousStatus = currentContact.status;  // Store the current status before update
  
      // Optimistically update UI
      setContacts(currentContacts =>
        currentContacts.map(c =>
          c.id === contactId
            ? { ...c, status: newStatus }
            : c
        )
      );
  
      const { error: updateError } = await supabase
        .from('contacts')
        .update({ status: newStatus })
        .eq('id', contactId);
  
      if (updateError) throw updateError;
  
      // If status is being set to NEW and there are no existing tasks, create the 45-day strategy tasks
      if (newStatus === 'NEW' && (!currentContact.tasks || currentContact.tasks.length === 0)) {
        await createTasksForContact(contactId, {
          includeNewTasks: false,  // Only include current tasks, not ones added later
          skipClosedContacts: true,
          startDate: new Date()
        });
      }
  
      // Refresh contacts to get updated tasks
      await fetchContacts();
    } catch (err) {
      console.error('Error updating contact status:', err);
      setError('Failed to update contact status. Please try again.');
      
      // Revert optimistic update on error using stored status
      setContacts(currentContacts =>
        currentContacts.map(c =>
          c.id === contactId
            ? { ...c, status: previousStatus }
            : c
        )
      );
    } finally {
      setUpdatingContact(null);
    }
  };

  const handleViewTasks = (contactId) => {
    navigate(`/contacts/${contactId}/tasks`);
  };

  const getNextTask = (tasks) => {
    if (!tasks || tasks.length === 0) return null;
    return tasks
      .filter(task => task.status === 'PENDING')
      .sort((a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date))[0];
  };

  // Filter contacts based on search and status
  const filteredContacts = contacts.filter(contact => {
    const searchString = `${contact.first_name} ${contact.last_name} ${contact.company}`.toLowerCase();
    const matchesSearch = !searchTerm || searchString.includes(searchTerm.toLowerCase());
    const matchesStatus = selectedStatus === 'ALL' || contact.status === selectedStatus;
    return matchesSearch && matchesStatus;
  });

  // Sort contacts
  const sortedContacts = [...filteredContacts].sort((a, b) => {
    const aValue = a[sortConfig.key] || '';
    const bValue = b[sortConfig.key] || '';
    const comparison = aValue.localeCompare(bValue);
    return sortConfig.direction === 'asc' ? comparison : -comparison;
  });

  const getTaskIcon = (taskType) => {
    switch (taskType) {
      case TASK_TYPES.EMAIL.name:
        return <Mail className="h-4 w-4" />;
      case TASK_TYPES.CALL.name:
        return <Phone className="h-4 w-4" />;
      case TASK_TYPES.LINKEDIN.name:
        return <Linkedin className="h-4 w-4" />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 text-red-800 rounded-lg">
        <h3 className="font-semibold">Error</h3>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
      {/* Header with search and filters */}
      <div className="p-4 border-b dark:border-gray-700">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          {/* Search */}
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
            <input
              type="text"
              placeholder="Search contacts..."
              value={searchTerm}
              onChange={handleSearch}
              className="pl-10 pr-4 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white w-full md:w-64"
            />
          </div>

          {/* Status Filter */}
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="ALL">All Statuses</option>
            {Object.values(CONTACT_STATUS).map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Contact Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th 
                className="px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort('last_name')}
              >
                <div className="flex items-center">
                  Name
                  {sortConfig.key === 'last_name' && (
                    sortConfig.direction === 'asc' ? 
                      <ChevronUp className="h-4 w-4 ml-1" /> : 
                      <ChevronDown className="h-4 w-4 ml-1" />
                  )}
                </div>
              </th>
              <th 
                className="px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort('company')}
              >
                <div className="flex items-center">
                  Company
                  {sortConfig.key === 'company' && (
                    sortConfig.direction === 'asc' ? 
                      <ChevronUp className="h-4 w-4 ml-1" /> : 
                      <ChevronDown className="h-4 w-4 ml-1" />
                  )}
                </div>
              </th>
              <th className="px-6 py-3 text-left">Next Task</th>
              <th className="px-6 py-3 text-left">Assigned To</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y dark:divide-gray-700">
            {sortedContacts.map(contact => {
              const nextTask = getNextTask(contact.tasks);
              
              return (
                <tr 
                  key={contact.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-gray-100">
                        {contact.first_name} {contact.last_name}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {contact.title}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                    <div className="text-gray-900 dark:text-gray-100">{contact.company}</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {contact.industry}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {nextTask && (
                      <div className="flex items-center">
                        {getTaskIcon(nextTask.task_type)}
                        <span className="ml-2 text-gray-900 dark:text-gray-100">
                          {new Date(nextTask.scheduled_date).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <select
                      value={contact.assigned_to || ''}
                      onChange={(e) => handleAssignTeamMember(contact.id, e.target.value || null)}
                      disabled={updatingContact === contact.id}
                      className={`border rounded px-2 py-1 text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white ${
                        updatingContact === contact.id ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      <option value="">Unassigned</option>
                      {teamMembers.map(member => (
                        <option key={member.id} value={member.id}>
                          {member.first_name} {member.last_name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="px-6 py-4">
                    <select
                      value={contact.status}
                      onChange={(e) => handleStatusChange(contact.id, e.target.value)}
                      disabled={updatingContact === contact.id}
                      className={`border rounded px-2 py-1 text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white ${
                        updatingContact === contact.id ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {Object.values(CONTACT_STATUS).map(status => (
                        <option key={status} value={status}>{status}</option>
                      ))}
                    </select>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <button 
                        onClick={() => window.open(contact.person_linkedin_url, '_blank')}
                        disabled={!contact.person_linkedin_url}
                        className={`text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 ${
                          !contact.person_linkedin_url ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <Linkedin className="h-5 w-5" />
                      </button>
                      <button 
                        onClick={() => window.location.href = `mailto:${contact.email}`}
                        disabled={!contact.email}
                        className={`text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 ${
                          !contact.email ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <Mail className="h-5 w-5" />
                      </button>
                      <button 
                        onClick={() => handleViewTasks(contact.id)}
                        className="text-green-600 hover:text-green-800 dark:text-green-400 dark:hover:text-green-300"
                        title="View Tasks"
                      >
                        <ListTodo className="h-5 w-5" />
                      </button>
                      {contact.first_phone && (
                        <button 
                          onClick={() => window.location.href = `tel:${contact.first_phone}`}
                          className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300"
                        >
                          <Phone className="h-5 w-5" />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Empty State */}
        {sortedContacts.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-500 dark:text-gray-400">
              No contacts found. Try adjusting your search or filters.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactList;